import _ from 'lodash'
import Vue from 'vue'
import cookie from 'vue-cookies'
import * as Sentry from '@sentry/vue'

const ADMIN_ROLES = ['ADMIN', 'SUPER_ADMIN']
const TRAINER_ROLE = 'TRAINER'

export const user = {
  namespaced: true,
  state: {
    authenticated: false,
    data: {},
    activity: [],
    prefs: {},
    equines: [], // List of equines that this user owns or leases.
    invoices: [], // List of user invoices.
    pendingInvoices: [], // List of invoices that are still draft.
  },
  mutations: {
    //use in components with this.$store.commit("user/setUserData", data)
    setUserData (state, payload) {
      state.authenticated = true
      state.data = payload
    },

    setUserEquines (state, payload) {
      state.equines = payload
    },
    setActivity (state, payload) {
      state.activity.splice(0, state.activity.length, ...payload)
    },
    setCalendarDisplay (state, payload) {
      Vue.set(state.prefs, 'calendarDisplay', payload)
      cookie.set('calendar_display', payload) //should structure inside of cookies better
    },
    setUserInvoices (state, payload) {
      state.invoices.splice(0, state.invoices.length, ...payload)
    },
    setPendingUserInvoices (state, payload) {
      state.pendingInvoices.splice(0, state.pendingInvoices.length, ...payload)
    },
    setLogout (state) {
      state.authenticated = false
      state.data = {}
      state.prefs = {}
      state.activity.splice(0)
      state.equines.splice(0)
      state.invoices.splice(0)
    },
  },

  actions: {
    setActivity ({commit}, payload) {
      return new Promise(resolve => resolve(commit('setActivity', payload)))
    },

    async setLogout ({commit}, router) {
      await this._vm.$firebase.signOut().then(() => {
        // complete logout before redirecting to login as login has logic that may try to fetch data
        // and potentially redirect back to dashboard if not logged out first.
        commit('setLogout')
        router.push('/login')
      })
    },

    /**
     * calls API to fetch fresh user data from backend.
     * @param commit
     * @param context
     * @returns {Promise<*>}
     */
    async refreshUser ({commit}, context) {
      return context.$API.User.getCurrentUserData()
        .then(res => {
          commit('setUserData', res.data.data)
        })
        .catch(err => {
          console.error(err)
          Sentry.captureException(err)
          context.$notify({type: 'danger', title: 'Error', message: err.message})
        })
    },

    /**
     * calls API to fetch fresh equine data for the currently logged in user.
     * @param commit
     * @param state
     * @param context
     * @returns {Promise<void>}
     */
    async refreshUserEquines ({commit, state}, context) {
      console.log('refreshing user equines')
      await context.$API.User.getUserEquines(state.data.id)
        .then(result => {
          commit('setUserEquines', result.data.data)
        })
        .catch(err => {
          console.error(err)
          Sentry.captureException(err)
          context.$notify({type: 'danger', title: 'Error', message: err.message})
        })
    },

    /**
     * convenience function that calls refreshUser followed by refreshUserEquines.
     * @param dispatch
     * @param context
     * @returns {Promise<*>}
     */
    async refreshUserWithEquines ({dispatch}, context) {
      dispatch('refreshUser', context).then(() => {
        dispatch('refreshUserEquines', context)
      })
    },

    /**
     * Calls API to fetch fresh user invoices for the currently logged-in user.
     * @param commit
     * @param context
     * @param monthYear {String} YYYY-MM
     * @param invoiceStatuses {Array} array of invoice statuses to filter by
     * @return {Promise<void>}
     */
    async refreshUserInvoices ({commit}, {context, monthYear, invoiceStatuses}) {
      let invoices = context.$API.User.getAllInvoices(monthYear, invoiceStatuses)
        .then(result => commit('setUserInvoices', result.data.data))
      let draftInvoices = context.$API.User
        .getAllInvoices(null, ['draft'], false)
        .then(result => commit('setPendingUserInvoices', result.data.data))
      Promise.all([invoices, draftInvoices]).catch(err => {
        console.error(err)
        Sentry.captureException(err)
        context.$notify({type: 'danger', title: 'Error', message: err.message})
      })
    },

  },
  getters: {
    isAuthenticated: (state) => state.authenticated,
    calendarDisplay (state) {
      return state.prefs.calendarDisplay
        ? state.prefs.calendarDisplay
        : cookie.get('calendar_display')
    },
    //use in components with this.$store.getters["user/userData"]
    userData (state) {
      return state.data === null ? {} : state.data
    },
    userId (state) {
      return state.data.id
    },

    /**
     * Will return orgRoles filtered by not being accepted, and grouped by orgId.
     *
     * @param state
     * @param getters
     * @returns {*[]}
     */
    pendingOrgInvites (state, getters) {
      let orgRoles = getters.userData.orgRoles ?? []
      let filtered = orgRoles.filter(value => {
        return value.orgAccepted === false || value.userAccepted === false
      })
      return Object.values(_.groupBy(filtered, 'orgId')).map(value => value[0])
    },
    userActivity (state) {
      return state.activity
    },
    /**
     * Pass in the stableRef and will return true/false if you are an admin of that stable.
     *
     * @see adminOfOrgIds
     * @param state
     * @param getters
     * @returns {function(Object): *}
     */
    isAdminOfStable (state, getters) {
      return stableRef => {
        let orgIds = getters.adminOfOrgIds
        return orgIds.has(stableRef.organisation.id)
      }
    },
    /**
     * Pass in the stableRef and will return true/false if you are an admin or trainer of that stable.
     * @param state
     * @param getters
     * @return {function(*): *}
     */
    isTrainerOrAdminOfStable (state, getters) {
      return stableRef => {
        let orgIds = getters.trainerOrAdminOfOrgIds
        return orgIds.has(stableRef.organisation.id)
      }
    },
    /**
     * Pass in the stableRef and will return true/false if you are a trainer of that stable.
     * @param state
     * @param getters
     * @returns {function(*): *}
     */
    isTrainerOfStable (state, getters) {
      return stableRef => {
        let orgIds = getters.trainerOfOrgIds
        return orgIds.has(stableRef.organisation.id)
      }
    },
    /**
     * Get the org id's that you are currently an admin of.
     *
     * @see isAdminOfStable
     * @returns {Set<any>|*}
     */
    adminOfOrgIds (state) {
      let orgIds = (state.data?.orgRoles ?? [])
        .filter(value => ADMIN_ROLES.includes(value.role))
        .map(value => value.orgId)
      return new Set(orgIds)
    },

    /**
     * Get the org id's that you are currently a trainer of.
     * @param state
     * @returns {Set<any>}
     */
    trainerOfOrgIds (state) {
      let roles = [TRAINER_ROLE]
      let orgIds = (state.data?.orgRoles ?? [])
        .filter(value => roles.includes(value.role))
        .map(value => value.orgId)
      return new Set(orgIds)
    },

    /**
     * Get the org id's that you are currently an admin or trainer of.
     *
     * @see trainerOrAdminOfOrgIds
     * @returns {Set<any>|*}
     */
    trainerOrAdminOfOrgIds (state) {
      let roles = [...ADMIN_ROLES, TRAINER_ROLE]
      let orgIds = (state.data?.orgRoles ?? [])
        .filter(value => roles.includes(value.role))
        .map(value => value.orgId)
      return new Set(orgIds)
    },

    userEquines (state) {
      return state.equines
    },
    /**
     * Get the user's invoices.
     * @return {[]}
     */
    userInvoices (state) {
      return state.invoices
    },

    /**
     * Get the user's pending invoices.
     * @param state
     * @returns {[]}
     */
    pendingUserInvoices (state) {
      return state.pendingInvoices
    },
  },
}
