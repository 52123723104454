export const schedule = {
  namespaced: true,
  state: {
    schedule: [],
    refresh: new Date(),
  },
  mutations: {
    //use in components with this.$store.commit("schedule/setSchedule", data)
    setSchedule (state, payload) {
      state.schedule.splice(0, state.schedule.length, ...payload)
    },
    resetStore (state) {
      state.schedule.splice(0)
    },
    updateRefresh (state) {
      state.refresh = new Date()
    },
  },
  getters: {
    //use in components with this.$store.getters["schedule/schedule"]
    schedule: (state) => state.schedule,
    refresh: (state) => state.refresh,
  },
  actions: {
    refresh (context) {
      return new Promise((resolve) => {
        context.commit('updateRefresh')
        resolve()
      })
    },
  },
}
