//build config object with reference to the vue instance as vueInstance
function apiConfig (vueInstance) {
  //build promise for API request(rq) and process the response before returning to component layer
  /**
   * Build a promise from Axios layer and print errors.
   *
   * @param rq AxiosRequest
   * @returns {Promise<AxiosResponse>}
   */
  function processCall (rq) {
    return new Promise(function (resolve, reject) {
      //after AXIOS API call chain promise response to handle middle layer processing
      rq
        .then(response => {
          if (response.status / 100 !== 2) {
            //if unexpected response log/notify
            console.error(
              'API Request - returned unexpected response', response)
          } else {
            //given a non-error response we still resolve and allow the user to handle as needed
            resolve(response)
          }
        })
        .catch(err => {
          //log server error
          console.error(
            'API Request - request failed with the following error: ', err,
          )
          //reject with error data to be processed by component layer
          if (err.response && err.response.data && err.response.data.error) {
            // If we have an API error we show that as the message
            reject(new Error(err.response.data.error))
          } else {
            reject(err)
          }
        })
    })
  }

  //return built config object with necessary methods
  return {
    //access to vue instance
    context: vueInstance,
    //access to axios/http provider
    api: vueInstance.$http,
    //access to public api without interceptor
    publicApi: vueInstance.$publicHttp,
    // builds/processes http request and returns promise
    processCall,
  }
}

export default apiConfig
