<template>
  <img
    :ix-host="host"
    :ix-path="path"
    :ix-params="params"
    :alt="alt"
  >
</template>

<script>
export default {
  name: 'ImgixImg',
  props: {
    host: {
      type: String,
      required: false,
      default: undefined,
    },
    path: {
      type: String,
      required: true,
    },
    alt: {
      type: String,
      default: '',
    },
    width: {
      type: [Number, String],
      required: true,
    },
    height: {
      type: [Number, String],
      required: true,
    },
    fit: {
      type: String,
      default: undefined,
    },
    crop: {
      type: String,
      default: undefined,
    },
  },
  computed: {
    params () {
      return JSON.stringify({
        w: this.width,
        h: this.height,
        fit: this.fit,
        crop: this.crop,
      })
    },
  },
  watch: {
    path () {
      // This waits for the dom to re render then will update the image tag :D
      this.$nextTick(() => {
        this.$imgix.init({ force: true })
      })
    },
  },
  mounted () {
    this.$imgix.init()
  },
}
</script>

<style scoped>

</style>
