import Vue from 'vue'
import Vuex from 'vuex'
import VuexPersistence from 'vuex-persist'
import { user } from './user'
import { schedule } from './schedule'
import { event_creation } from './event_creation'
import { stables } from './stables'
import { ui } from './ui'
import { equines } from '@/store/equines'

const vuexLocal = new VuexPersistence({
  key: 'haynet-vuex-storage',
  storage: window.localStorage,
  modules: ['stables', 'user', 'schedule', 'equines'],
})

Vue.use(Vuex)

/*use modules to separate the logical groupings of data out to give clarity
  see reference here: https://vuex.vuejs.org/guide/modules.html*/
const modules = {
  user,
  schedule,
  event_creation,
  stables,
  equines,
  ui,
}

/* Building the store instance to export */
const store = new Vuex.Store({
  modules/*,
  state,
  mutations,
  actions,
  getters*/,
  plugins: [vuexLocal.plugin],
})

/* Export for reference */
export default store
