import {
  copyRecurrenceRulesToAnotherDay,
  createWindow,
  dayAsNumber,
  dayIndex,
  convertToCents,
} from './eventCreationHelpers'
import moment from 'moment'

const DAYS_OF_WEEK = [
  'monday',
  'tuesday',
  'wednesday',
  'thursday',
  'friday',
  'saturday',
  'sunday',
]

export const event_creation = {
  namespaced: true,
  state: {
    stableId: null,
    isEditMode: false,
    editDataIsLoaded: false,

    event: {
      instanceId: null, // Only used for editing existing event.
      type: null,
      title: null,
      description: null,
      defaultDurationMins: null,
      preDurationMins: null,
      postDurationMins: null,
      // Only provide an override value if you want to use a different value
      // at the instance level that what's set at the event level.
      overrideBookingDurationMins: null, // not implemented yet.
      notes: null,
      /**
       * Array of trainers for this event:
       * - null is no trainers
       * - empty is any
       * - populated array eg. [{userId:'id'}] is what trainers the clients
       * are allowed to book with.
       */
      trainers: [],
      trainersOptional: false,
      /**
       * Array of {EventResourceResponse} objects.
       */
      resources: [],
      visibility: null, // PRIVATE, ORGANISATION, or PUBLIC
    },

    // when isBookingWindow is true, defaultDurationMins is mandatory.
    // Otherwise false and overrideBookingDurationMins can be used.
    isBookingWindow: true,

    clients: {},

    // todo
    // equines: {}

    /**
     * An object that contains scheduling data for this event including
     * @property rangeStart - an ISO8601 format DateTime obj for the outermost
     * scheduling window start date for this event.
     * @property rangeEnd - an ISO8601 format DateTime obj for the outermost scheduling window end date for this event.
     *
     */
    schedule: {
      /**
       * The outermost scheduling range start [DateTime] for this event.
       */
      rangeStart: null,
      /**
       * The outermost scheduling range end [DateTime] for this event.
       */
      rangeEnd: null,

      /**
       * A schedule window is an object that describes an inner scheduling range within [rangeStart] and [rangeEnd].
       * The data is grouped by day of the week with Monday (1) being the first day of the week and Sunday (7)
       * is the end of the week.
       *
       * Each day object contains an array of inner objects describing the following window properties:
       * start dateTime, end dateTime, all day, all day business hours, if the window is recurring,
       * recurrence pattern, recurrence end rule.
       *
       * sample range object
       * rangeWindows: {
       *   monday: [{
       *     startDateTime: DateTime,
       *     endDateTime: DateTime,
       *     isAllDay
       *   }]
       * }
       *
       */
      rangeWindows: [
        [], // monday
        [], // tuesday
        [], // wednesday
        [], // thursday
        [], // friday
        [], // saturday
        [], // sunday
      ],

      // For UI handling only
      checkedDays: [],
    },

    maxGroupSize: 1, // 1 for private or null for no limit.
    maxConcurrentBookings: null,
    color: null,
    published: null,

    allowClientToSchedule: true,
    allowClientToReschedule: true,
    allowClientToCancel: true,

    maxScheduleFromStartPeriod: null,
    minScheduleFromStartPeriod: null,
    minReschedulePeriod: null,
    minCancelPeriod: null,
    overrideLateCancelFeePercent: null,
    // recurrenceModification is set to `ALL` to modify all occurrences of a recurring pattern. This is fine to
    // default to 'ALL' for predefined events. Accepted values: 'ALL', 'THIS', 'THIS_AND_FUTURE'
    recurrenceModification: 'ALL',

    billing: {
      eventAmount: 0, // use lowest currency unit (i.e. cents) $45.00 USD = 4500
      currency: 'usd', // three letter lowercase
      payerRoles: ['CLIENT'], // default to client for now we can expand later
      // These are now nullable on events as they can be set globally. They are required if not set globally.
      invoicePeriod: null, // IMMEDIATE, DAILY, WEEKLY, MONTHLY, YEARLY
      // Weekly = 1..7,  1 = Monday 7 = Sunday,
      // Monthly = 1-31 (we will run the billing at the last day of the month if less than 31)
      invoiceDay: null,
      schoolEquineAmount: 0,
      addOns: [],
      tokenTypes: [],
    },
    /*  Data structure example:
    addOnItem: {
      id: null,
      name: '',
      amount: -1,
      description: '',
    },*/
  },

  getters: {
    stableId: state => state.stableId,
    isEditMode: state => state.isEditMode,
    editDataIsLoaded: state => state.editDataIsLoaded,
    eventId: state => state.event.id,
    event: state => state.event,
    trainers: state => state.event.trainers,
    trainersOptional: state => state.event.trainersOptional,
    resources: state => state.event.resources,
    clients: state => state.clients,
    maxGroupSize: state => state.maxGroupSize,
    maxConcurrentBookings: state => state.maxConcurrentBookings,
    // canClientSchedule: state => state.allowClientToSchedule,
    // canClientReschedule: state => state.allowClientToReschedule,
    // canClientCancel: state => state.allowClientToCancel,
    maxSchedulePeriod: state => state.maxScheduleFromStartPeriod,
    minSchedulePeriod: state => state.minScheduleFromStartPeriod,
    minReschedulePeriod: state => state.minReschedulePeriod,
    minCancelPeriod: state => state.minCancelPeriod,
    overrideLateCancelFeePercent: state => state.overrideLateCancelFeePercent,

    defaultDurationMins: state => state.event.defaultDurationMins,
    preDurationMins: state => state.event.preDurationMins,
    postDurationMins: state => state.event.postDurationMins,
    overrideBookingDurationMins: state => state.event.overrideBookingDurationMins,
    type: state => state.event.type,
    notes: state => state.event.notes,
    description: state => state.event.description,
    title: state => state.event.title,
    color: state => state.color,
    visibility: state => state.event.visibility,
    published: state => state.published,

    // Getters for range and window objects.
    schedule: state => state.schedule,
    rangeStart: state => state.schedule.rangeStart,
    rangeEnd: state => state.schedule.rangeEnd,
    rangeWindows: state => state.schedule.rangeWindows,

    // Getters for billing
    billing: state => state.billing,
    eventAmount: state => state.billing.eventAmount, // returns the stored value which is converted to lowest currency unit
    currency: state => state.billing.currency,
    payerRoles: state => state.billing.payerRoles,
    invoicePeriod: state => state.billing.invoicePeriod,
    invoiceDay: state => state.billing.invoiceDay,
    schoolEquineAmount: state => state.billing.schoolEquineAmount,
    addOns: state => state.billing.addOns,
    stateBillingTokenTypes: state => state.billing.tokenTypes,

    // ui-only relevant properties
    checkedDays: state => state.schedule.checkedDays,

    getNextWindowId: state => dayOfWeekStr => {
      const dayNumber = dayAsNumber(dayOfWeekStr)
      const windows = state.schedule.rangeWindows[dayIndex(dayOfWeekStr)]
      // return windows.length < 1 ? dayNumber * 100 : (dayNumber * 100 + windows.length) <-- don't re-use ids.
      return windows.length < 1 ? dayNumber * 100 : windows[windows.length -
      1].windowId + 1
    },

    getIsAllDay: () => ({ dayOfWeekStr, windowId }) => {
      const window = this.getWindowForId({ dayOfWeekStr, windowId })
      return window.isAllDay
    },

    getWindowForId: (state) => ({ dayOfWeekStr, windowId }) => {
      const windows = state.schedule.rangeWindows[dayIndex(dayOfWeekStr)]
      return windows.find(function (element) {
        return element.windowId === windowId
      })
    },

    getWindowForInstanceId: (state) => ({ dayOfWeekStr, instanceId }) => {
      const windows = state.schedule.rangeWindows[dayIndex(dayOfWeekStr)]
      return windows.find(function (element) {
        return element.instanceId === instanceId
      })
    },

    getAllWindowsForDay: (state) => (dayString) => {
      return state.schedule.rangeWindows[dayIndex(dayString)]
    },

    getCompleteEventData: (state) => {
      const instancesArray = []
      const rangeWindows = state.schedule.rangeWindows
      for (let i = 0; i < rangeWindows.length; i++) {
        const dayArray = rangeWindows[i]
        for (let j = 0; j < dayArray.length; j++) {
          const window = dayArray[j]
          const instance = {
            id: window.instanceId == null ? window.id : window.instanceId,
            bookingWindow: state.isBookingWindow,
            title: state.event.title,
            notes: state.event.notes,
            // overrideMaxGroupSize: state.maxGroupSize, // todo
            // overrideConcurrentBookings: state.maxConcurrentBookings, // todo
            startDateTime: moment(window.startDateTime).format(),
            endDateTime: moment(window.endDateTime).format(),
            timeZoneName: window.timeZoneName,
            allDay: window.isAllDay,
            recurrenceModification: state.recurrenceModification,
          }

          // Sets a duration at the instance level if it needs to override the event level duration.
          if (state.event.overrideBookingDurationMins != null) {
            instance.bookingDurationMins = state.event.overrideBookingDurationMins
          }

          if (window.isRecurring) {
            const recEndDate = window.recurrenceRules.recurrenceEndDate
            instance.recurrenceType = window.recurrenceRules.recurrenceType
            instance.recurrenceSeparation = window.recurrenceRules.recurrenceSeparation
            instance.recurrenceDayOfWeek = window.recurrenceRules.recurrenceDayOfWeek
            instance.recurrenceWeekOfMonth = window.recurrenceRules.recurrenceWeekOfMonth
            instance.recurrenceDayOfMonth = window.recurrenceRules.recurrenceDayOfMonth
            instance.recurrenceMonthOfYear = window.recurrenceRules.recurrenceMonthOfYear
            instance.recurrenceEndDate = recEndDate != null ? moment(recEndDate)
              .format('YYYY-MM-DD') : null
            instance.recurrenceCount = window.recurrenceRules.recurrenceCount
          }

          instancesArray.push(instance)
        }
      }

      let billing = {}
      // Only store the billing data if they have set a cost for the event.
      // Right now we don't allow users to set 0 cost but then include add-on fees.
      if (state.billing.eventAmount >= 0) {
        billing = {
          eventAmount: state.billing.eventAmount,
          currency: state.billing.currency,
          // payerRoles: state.billing.payerRoles, // using server default for now.
          invoicePeriod: state.billing.invoicePeriod,
          invoiceDay: state.billing.invoiceDay,
          schoolEquineAmount: state.billing.eventAmount > 0 ? state.billing.schoolEquineAmount : 0,
          // addOns: state.billing.eventAmount > 0 ? state.billing.addOns : 0,
          tokenTypes: state.billing.tokenTypes,
        }
      }

      return {
        id: state.isEditMode ? state.event.id : null,
        name: state.event.title,
        description: state.event.description,
        stableId: state.stableId,
        type: { id: state.event.type },
        defaultDurationMins: state.event.defaultDurationMins,
        preDurationMins: state.event.preDurationMins,
        postDurationMins: state.event.postDurationMins,
        trainers: state.event.trainers,
        trainersOptional: state.event.trainersOptional,
        resources: state.event.resources,
        defaultGroupSize: state.maxGroupSize,
        defaultConcurrentBookings: state.maxConcurrentBookings,
        color: state.color != null ? state.color.substring(1) : null,
        visibility: state.event.visibility,
        published: state.published != null
          ? moment(state.published).format()
          : null,
        instances: instancesArray,
        allowClientToSchedule: state.allowClientToSchedule,
        allowClientToReschedule: state.allowClientToReschedule,
        allowClientToCancel: state.allowClientToCancel,
        minScheduleFromStartPeriod: state.minScheduleFromStartPeriod,
        maxScheduleFromStartPeriod: state.maxScheduleFromStartPeriod,
        minReschedulePeriod: state.minReschedulePeriod,
        minCancelPeriod: state.minCancelPeriod,
        overrideLateCancelFeePercent: state.overrideLateCancelFeePercent,
        billing: billing,
      }
    },

    getInputValidationErrors: (state) => {
      const errors = []

      // Event object required field errors.
      if (state.event.title == null) {
        errors.push('Title is a required field.')
      }
      if (state.event.type == null) {
        errors.push('Event Type is a required field.')
      }
      if (state.event.defaultDurationMins == null) {
        errors.push('Duration is a required field.')
      }

      // Window data.
      if (state.schedule.rangeStart == null) {
        errors.push('Schedule Range Start is a required field.')
      }
      if (state.schedule.checkedDays.length === 0) {
        errors.push(
          'It doesn\'t look like you\'ve selected any schedulable days.')
      }

      // Billing data.
      if (state.billing.eventAmount < 0) {
        errors.push('You cannot set a negative price for the event amount.')
      }
      if (state.billing.schoolEquineAmount < 0) {
        errors.push('You cannot set a negative price for the School Horse fee.')
      }

      if (state.billing.tokenTypes.length > 0 && state.billing.eventAmount <= 0) {
        errors.push('You must set a valid price for the event before enabling pay with credit tokens.')
      }

      // if (state.billing.eventAmount <= 0 && (state.billing.addOns.length > 0 || state.billing.schoolEquineAmount > 0)) {
      if (state.billing.eventAmount <= 0 && state.billing.schoolEquineAmount > 0) {
        errors.push('You must set a valid price for the event before adding school horse fees or add-ons.')
      }

      // Validate that time window values are valid.
      const windows = state.schedule.rangeWindows
      let timeWindowsCount = 0

      for (let i = 0; i < windows.length; i++) {
        const dayArray = windows[i]
        timeWindowsCount += dayArray.length

        dayArray.forEach(window => {
          if (window.startDateTime >= window.endDateTime) {
            errors.push('End time must be after the Start time.')
          }
          if (window.isRecurring && window.recurrenceRules.recurrenceEndDate !=
            null) {
            const recurEnd = moment(window.recurrenceRules.recurrenceEndDate)
              .format()
            const start = moment(window.startDateTime).format()
            if (recurEnd < start) {
              errors.push(
                'Recurring end date must come after the schedulable start time.')
            }
          }
        })
      }

      if (timeWindowsCount < 1) {
        errors.push(
          'It looks like you haven\'t selected any scheduling window time frames.')
      }
      // console.log("validation errors = ", errors)
      return errors
    },
  },

  /*
  sample json

  {
    "name": "Facility Use 2",
    "description": null,
    "stableId": "bd4109af-1987-432a-a49f-2def7941c377",
    "type": {
        "id": 3
    },
    "trainers": [],
    "trainersOptional": false,
    "resources": [],
    "defaultDurationMins": 60,
    "defaultGroupSize": null,
    "defaultConcurrentBookings": null,
    "color": "abcdef",
    "published": "2020-04-06T8:00:00-07:00",
    "instances": [
        {
            "title": null,
            "notes": null,
            "startDateTime": "2020-04-06T8:00:00-07:00",
            "endDateTime": "2020-04-06T11:00:00-07:00",
            "timeZoneName": "America/Denver",
            "isFullDayEvent": false,
            "overrideMaxGroupSize": 2,
            "overrideConcurrentBookings": null,
            "recurrenceType": "WEEKLY",
            "recurrenceDayOfWeek": 1,
            "recurrenceEndDate": null,
            "bookingDurationMins": 60
        },
        {
            "title": null,
            "notes": null,
            "startDateTime": "2020-04-07T8:00:00-07:00",
            "endDateTime": "2020-04-07T11:00:00-07:00",
            "timeZoneName": "America/Denver",
            "isFullDayEvent": false,
            "recurrenceType": "WEEKLY",
            "recurrenceDayOfWeek": 2,
            "recurrenceEndDate": null,
            "bookingDurationMins": 60 // only set if overriding the top level event duration.

            "recurrenceType":"MONTHLY",
            "recurrenceSeparation":1,
            "recurrenceDayOfWeek":2,
            "recurrenceWeekOfMonth":1,
            "recurrenceDayOfMonth":21,
            "recurrenceMonthOfYear":null,
            "recurrenceEndDate":null,
            "recurrenceCount":null

        }
    ]
}

   */

  mutations: {
    updateIsEditMode (state, isEditMode) {
      state.isEditMode = isEditMode
    },

    // updateEditDataIsLoaded (state, editDataLoaded) {
    //   state.editDataIsLoaded = editDataLoaded
    // },

    updateEventId (state, eventId) {
      state.event.id = eventId
    },

    updateStableId (state, stableId) {
      state.stableId = stableId
    },

    updateType (state, type) {
      state.event.type = type
    },

    updateNotes (state, notes) {
      state.event.notes = notes
    },

    updateDescription (state, description) {
      state.event.description = description
    },

    updateDefaultDurationMins (state, durationMins) {
      state.event.defaultDurationMins = durationMins
    },

    updatePreDurationMins (state, mins) {
      let duration = mins
      if (duration < 1) { duration = null }
      state.event.preDurationMins = duration
    },

    updatePostDurationMins (state, mins) {
      let duration = mins
      if (duration < 1) { duration = null }
      state.event.postDurationMins = duration
    },

    updateOverrideBookingDurationMins (state, overrideDurationMins) {
      state.event.overrideBookingDurationMins = overrideDurationMins
    },

    updateTitle (state, title) {
      state.event.title = title
    },

    updateColor (state, color) {
      state.color = color
    },

    updateVisibility (state, visibility) {
      state.event.visibility = visibility
    },

    updatePublished (state, published) {
      state.published = published
    },

    updateTrainers (state, trainers) {
      state.event.trainers = trainers
    },

    updateTrainersOptional (state, trainersOptional) {
      state.event.trainersOptional = trainersOptional
    },

    updateResources (state, resources) {
      state.event.resources = resources
    },

    updateRangeStart (state, startDateTime) {
      state.schedule.rangeStart = startDateTime
    },
    updateRangeEnd (state, endDateTime) {
      state.schedule.rangeEnd = endDateTime
    },

    updateCheckedDays (state, checkedArray) {
      checkedArray.sort(function (lhs, rhs) {
        return dayAsNumber(lhs) - dayAsNumber(rhs)
      })
      state.schedule.checkedDays = checkedArray.slice()
    },

    /**
     * Updates the window for the given {@param dayOfWeekStr} for the given index.
     * If the object does not exist, it is added to the array.
     * @param state
     * @param instanceId - can be null, only for updating an existing instance window.
     * @param windowId
     * @param dayOfWeekStr
     * @param startDateTime
     * @param endDateTime
     * @param isAllDay
     * @param isRecurring
     * @param recurrenceRules
     */
    updateWindow (
      state,
      { instanceId, windowId, dayOfWeekStr, startDateTime, endDateTime, isAllDay, isRecurring, recurrenceRules }) {
      if (windowId === undefined) {
        return
      }
      const windowsForDay = state.schedule.rangeWindows[dayIndex(dayOfWeekStr)]

      let existingWindow = null
      let existingWindowIndex = null
      windowsForDay.forEach(function (window, index) {
        if (window.windowId === windowId) {
          existingWindow = window
          existingWindowIndex = index
        }
      })

      const newWindow = createWindow(
        state, instanceId, windowId, dayOfWeekStr, startDateTime, endDateTime,
        isAllDay, isRecurring, recurrenceRules,
      )

      if (existingWindow != null) {
        windowsForDay.splice(existingWindowIndex, 1, newWindow)
      } else {
        windowsForDay.push(newWindow)
      }
    },

    removeWindow (state, { windowId, dayOfWeekStr }) {
      const ranges = state.schedule.rangeWindows[dayIndex(dayOfWeekStr)]
      ranges.forEach(function (window, index) {
        if (window.windowId === windowId) {
          ranges.splice(index, 1)
        }
      })
    },

    removeAllWindowsForDay (state, dayStr) {
      state.schedule.rangeWindows[dayIndex(dayStr)].length = 0
    },

    /**
     * Resets all windows for the entire week.
     * @param state
     */
    clearAllWindows (state) {
      const windows = state.schedule.rangeWindows
      for (let i = 0; i < windows.length; i++) {
        windows[i] = []
      }
      state.schedule.checkedDays = []
    },

    updateMaxGroupSize (state, maxSize) {
      state.maxGroupSize = maxSize
    },

    updateMaxConcurrentBookings (state, maxConcurrency) {
      state.maxConcurrentBookings = maxConcurrency
    },

    // Booking rules
    setAllowClientSchedule (state, clientCanSchedule) {
      state.allowClientToSchedule = clientCanSchedule
    },

    setAllowClientReschedule (state, clientCanReschedule) {
      state.allowClientToReschedule = clientCanReschedule
    },

    setAllowClientCancel (state, clientCanCancel) {
      state.allowClientToCancel = clientCanCancel
    },

    setMinSchedulePeriod (state, minutes) {
      state.minScheduleFromStartPeriod = minutes
    },

    setMaxSchedulePeriod (state, minutes) {
      state.maxScheduleFromStartPeriod = minutes
    },

    setMinReschedulePeriod (state, minutes) {
      state.minReschedulePeriod = minutes
    },

    setMinCancelPeriod (state, minutes) {
      state.minCancelPeriod = minutes
    },
    setOverrideLateCancelFeePercent (state, percent) {
      state.overrideLateCancelFeePercent = percent
    },

    // Billing mutators
    setBilling (state, billingObject, resetToDefaults) {
      // ensure we have a fully formed object that front end expects.
      let result = {
        eventAmount: 0,
        currency: 'usd',
        payerRoles: ['CLIENT'],
        invoicePeriod: null,
        invoiceDay: null,
        schoolEquineAmount: 0,
        // addOns: [],
      }

      if (!resetToDefaults) {
        result = Object.assign(result, billingObject)
      }
      state.billing = result
    },

    setEventAmount (state, amount) {
      state.billing.eventAmount = convertToCents(amount)
    },

    setCurrency (state, currency) {
      state.billing.currency = currency
    },

    setPayerRoles (state, payerRoles) {
      state.billing.payerRoles = payerRoles
    },

    setInvoicePeriod (state, invoicePeriod) {
      if(invoicePeriod === '') state.billing.invoicePeriod = null
      else state.billing.invoicePeriod = invoicePeriod
    },

    setInvoiceDay (state, invoiceDay) {
      state.billing.invoiceDay = invoiceDay
    },

    setSchoolEquineAmount (state, schoolEquineAmount) {
      state.billing.schoolEquineAmount = convertToCents(schoolEquineAmount)
    },

    /**
     * Replaces the current array with the new array.
     * @param state
     * @param addOnsArray
     */
    setAddOns (state, addOnsArray) {
      state.billing.addOns = addOnsArray
    },

    /**
     * Set token types to creation store
     * @param state
     * @param tokenTypes [{Object}]
     */
    setBillingTokenTypes (state, tokenTypes) {
      state.billing.tokenTypes.splice(0, state.billing.tokenTypes.length, ...tokenTypes)
    },

    resetEventCreateState (state) {
      console.log('resetting store data for event ', state.eventId)
      if (state.billing.addOns.length > 0) state.billing.addOns.length = 0
      state.billing = {
        eventAmount: 0,
        currency: 'usd',
        payerRoles: ['CLIENT'],
        invoicePeriod: null,
        invoiceDay: null,
        schoolEquineAmount: 0,
        addOns: [],
        tokenTypes: [],
      }

      state.schedule.rangeStart = null
      state.schedule.rangeEnd = null
      state.schedule.rangeWindows = [[], [], [], [], [], [], []]
      state.schedule.checkedDays = []
      state.clients = {}
      state.maxConcurrentBookings = null
      state.maxGroupSize = 1 // defaults to private.
      state.allowClientToSchedule = true
      state.allowClientToReschedule = true
      state.allowClientToCancel = true
      state.minScheduleFromStartPeriod = null
      state.maxScheduleFromStartPeriod = null
      state.minReschedulePeriod = null
      state.minCancelPeriod = null
      state.color = null
      state.event.visibility = null
      state.published = null
      state.isEditMode = false

      state.event.title = null
      state.event.description = null
      state.event.type = null
      state.event.notes = null
      state.event.visibility = null
      state.event.defaultDurationMins = null
      state.event.preDurationMins = null
      state.event.postDurationMins = null
      state.event.overrideBookingDurationMins = null
      state.event.trainers = []
      state.event.trainersOptional = false
      state.event.resources = []
      state.event.id = null
    },
  },

  actions: {
    updateRecurringAction (
      { commit, state },
      { dayOfWeekStr, windowId, isRecurring, recurrenceRules }) {
      let dayIndex = DAYS_OF_WEEK.indexOf(dayOfWeekStr.toLowerCase())
      const windows = state.schedule.rangeWindows[dayIndex]
      const window = windows.find(function (element) {
        return element.windowId === windowId
      })

      const updated = {
        instanceId: window.instanceId,
        windowId: window.windowId,
        dayOfWeekStr: dayOfWeekStr,
        startDateTime: window.startDateTime,
        endDateTime: window.endDateTime,
        isAllDay: window.isAllDay,
        isRecurring: isRecurring,
        recurrenceRules: recurrenceRules,
      }

      const endDate = recurrenceRules.recurrenceEndDate
      if (endDate != null) {
        // set seconds to zero.
        updated.recurrenceRules.recurrenceEndDate = moment(endDate).seconds(0)
          .format()
      }
      commit('updateWindow', updated)
    },

    copyWindowToAllCheckedDays (
      { state, getters, commit }, { dayOfWeekStr, windowId }) {
      const dayWindows = state.schedule.rangeWindows[dayIndex(dayOfWeekStr)]

      let copyWindow = dayWindows.find(function (element) {
        return element.windowId === windowId
      })

      const rangeStartDate = state.schedule.rangeStart
      const checkedDays = state.schedule.checkedDays

      for (let i = 0; i < checkedDays.length; i++) {
        const dayString = checkedDays[i]

        // Skip the day we are copying from.
        if (dayString.toLowerCase() === dayOfWeekStr.toLowerCase()) continue
        // Skip if the day is already marked for All Day.
        const iWindows = state.schedule.rangeWindows[dayIndex(dayString)]
        if (iWindows[0] != null && iWindows[0].isAllDay) continue

        // Copy of recurrence rules to apply to this day.
        const recurrenceRules = copyRecurrenceRulesToAnotherDay(state, {
          dayOfWeekStr: dayString,
          copyWindow: copyWindow,
          rangeStartDate,
        })

        if (copyWindow.isAllDay) {
          // Delete the other windows since the all day window covers, the entire day.
          state.schedule.rangeWindows[dayIndex(dayString)] = [] //.length = 0
        }

        const newWindowId = getters.getNextWindowId(dayString)
        const newWindow = {
          instanceId: null,
          windowId: newWindowId,
          dayOfWeekStr: dayString,
          startDateTime: copyWindow.startDateTime,
          endDateTime: copyWindow.endDateTime,
          isAllDay: copyWindow.isAllDay,
          isRecurring: copyWindow.isRecurring,
          recurrenceRules: recurrenceRules,
        }
        commit('updateWindow', newWindow)
      }
    },

    mapEditEventData (context, event) {
      console.log('mapEditEventData called addOns = ', event.billing.addOns)
      context.commit('updateIsEditMode', true)
      context.commit('updateEventId', event.id)
      context.commit('updateType', event.type.id)
      context.commit('updateTitle', event.name)
      context.commit('updateDescription', event.description)
      context.commit('updateNotes', event.instances[0].notes)
      context.commit('updateVisibility', event.visibility)
      context.commit('updateDefaultDurationMins', event.defaultDurationMins)
      context.commit('updatePreDurationMins', event.preDurationMins)
      context.commit('updatePostDurationMins', event.postDurationMins)
      // context.commit('updateOverrideBookingDurationMins',)
      context.commit('updateMaxGroupSize', event.defaultGroupSize)
      context.commit(
        'updateMaxConcurrentBookings', event.defaultConcurrentBookings)
      context.commit('setAllowClientSchedule', event.allowClientToSchedule)
      context.commit('setAllowClientReschedule', event.allowClientToReschedule)
      context.commit('setAllowClientCancel', event.allowClientToCancel)
      context.commit('setMinSchedulePeriod', event.minScheduleFromStartPeriod)
      context.commit('setMaxSchedulePeriod', event.maxScheduleFromStartPeriod)
      context.commit('setMinReschedulePeriod', event.minReschedulePeriod)
      context.commit('setMinCancelPeriod', event.minCancelPeriod)
      context.commit('setOverrideLateCancelFeePercent', event.overrideLateCancelFeePercent)
      context.commit('setBilling', event.billing)
      context.commit('updateColor', event.color != null ? event.color : null)
      context.commit(
        'updatePublished',
        event.published != null ? moment(event.published).format() : null,
      )
      context.commit('updateTrainers', event.trainers)
      context.commit('updateTrainersOptional', event.trainersOptional)
      context.commit('updateResources', event.resources)

      // console.log("event instances count = ", event.instances.length)

      let rangeStartValue = event.instances[0].startDateTime
      event.instances.forEach(instance => {
        const startMoment = moment(instance.startDateTime)
        if (moment(rangeStartValue) > startMoment) {
          rangeStartValue = instance.startDateTime
        }
      })
      context.commit('updateRangeStart', rangeStartValue)
      // We're not using this. We may never use this, but leaving as things are changing on a daily basis and
      // we could very well change back to using an end value.
      context.commit('updateRangeEnd', null)

      // Add the instance windows.
      let checkedDays = []
      event.instances.forEach((instance) => {
        // console.log("instance = ", instance)
        if (!instance.bookingWindow || instance.expired) return
        const dayString = moment(instance.startDateTime).format('dddd')
        checkedDays.push(dayString)

        let isRecurring = instance.recurrenceType != null
        let recurrenceRules
        if (isRecurring) {
          recurrenceRules = {
            isRecurring: isRecurring,
            selectedOptionValue: null,
            recurrenceType: instance.recurrenceType,
            recurrenceSeparation: instance.recurrenceSeparation || null,
            recurrenceDayOfWeek: instance.recurrenceDayOfWeek || null,
            recurrenceWeekOfMonth: instance.recurrenceWeekOfMonth || null,
            recurrenceDayOfMonth: instance.recurrenceDayOfMonth || null,
            recurrenceMonthOfYear: instance.recurrenceMonthOfYear || null,
            recurrenceEndDate: instance.recurrenceEndDate || null,
            recurrenceCount: instance.recurrenceCount || null,
          }
        }

        const windowId = context.getters.getNextWindowId(dayString)

        checkedDays = [...new Set(checkedDays)]
        context.commit('updateCheckedDays', checkedDays)
        context.commit('updateRangeStart', rangeStartValue)
        context.commit('updateRangeEnd', null)

        const window = {
          instanceId: instance.id,
          windowId: windowId,
          dayOfWeekStr: dayString,
          startDateTime: instance.startDateTime,
          endDateTime: instance.endDateTime,
          isAllDay: instance.allDay,
          isRecurring: isRecurring,
          recurrenceRules: recurrenceRules,
        }
        context.commit('updateWindow', window)
      })

      checkedDays = [...new Set(checkedDays)]
      context.commit('updateCheckedDays', checkedDays)
    },
  },
}

// Sample response
// {id: "78e2740d-d4ea-44ca-a761-d82be96ce29f", stableId: "c6280d9b-08c1-4160-bf8d-c85f48fea3d1", facilityId: null, name: "fdsa", description: "", …}
// color: "58ff41"
// defaultConcurrentBookings: null
// defaultDurationMins: 22
// defaultGroupSize: null
// description: ""
// facilityId: null
// id: "78e2740d-d4ea-44ca-a761-d82be96ce29f"
// instances: [{…}]
// name: "fdsa"
// published: "2020-04-24T21:44:00Z"
// stableId: "c6280d9b-08c1-4160-bf8d-c85f48fea3d1"
// trainers: "*"
// type: {

// 0:
// allDay: false
// bookingDurationDefault: false
// endDateTime: "2020-04-28T18:45:00-06:00"
// event:
//   color: "a6ff65"
// defaultConcurrentBookings: 2
// defaultDurationMins: 22
// defaultGroupSize: 2
// description: ""
// facilityId: null
// id: "944e0c23-ef95-4fe5-8179-65ce0baf8ea2"
// instances: []
// name: "dfa"
// published: "2020-04-24T22:46:00Z"
// stableId: "c6280d9b-08c1-4160-bf8d-c85f48fea3d1"
// trainers: "*"
// type:
//   clientRequired: false
// horseRequired: true
// id: 2
// name: "Training Session"
// trainersRequired: true
// __proto__: Object
// __proto__: Object
// expired: false
// id: "67c90c1d-b96d-4b7d-ac96-c6de6dac1735"
// recurrenceDayOfWeek: 2
// recurrenceType: "WEEKLY"
// startDateTime: "2020-04-28T16:45:00-06:00"
// timeZoneName: "America/Denver"
// title: "dfa"
