<template>
  <div class="wrapper">
    <notifications-component />
    <side-bar-component
      v-if="!isFullScreenMode"
      :logo="stableLogo"
    >
      <hr class="m-4">
      <template #links>
        <SidebarUser />
        <!-- <SidebarHorses></SidebarHorses> -->

        <sidebar-item
          :link="{
            name: 'Dashboard',
            icon: 'fas fa-th-large text-orange',
            path: '/dashboard'
          }"
        />

        <!-- Account -->
        <SidebarItem
          :link="{ name: 'Account', icon: 'fa-solid fa-user-circle' }"
        >
          <div
            v-for="stableRef in stables"
            :key="stableRef.stable.id"
          >
            <sidebar-item
              :link="{
                name: 'Billing',
                path: `/stable/${stableRef.stable.id}/billing`
              }"
            />
            <sidebar-item
              v-if="showProducts || showBoarders"
              :link="{
                name: 'Subscriptions',
                path: `/stable/${stableRef.stable.id}/subscriptions`
              }"
            />
            <sidebar-item
              v-if="showProducts && isAdminOfStable(stableRef)"
              :link="{
                name: 'Packages &amp; Board Products',
                path: `/stable/${stableRef.stable.id}/products`
              }"
            />
          </div>
          <!-- <sidebar-item :link="{ name: 'PackagesPage', path: '/packages' }" />-->
        </SidebarItem>

        <!-- Schedule Book/Calendar -->
        <sidebar-item
          v-if="stables.length > 0"
          :collapsed="false"
          :link="{
            collapsed: false,
            name: 'Schedule Book',
            icon: 'far fa-calendar-range text-info'
          }"
        >
          <sidebar-item :link="{ name: 'Calendar', path: '/schedule' }" />
          <!-- Don't change path without creating a redirect for email links -->
          <template v-for="sRef in stables">
            <sidebar-item
              v-if="!isAdminOrTrainerOfStable(sRef)"
              :key="sRef.stable.id"
              :link="{
                name: 'Quick Schedule', path: '/booking?stableId=' + sRef.stable.id,
              }"
            />
          </template>
          <!--          <sidebar-item disabled :link="{ name: 'Training', path: '/training' }"/>-->
        </sidebar-item>

        <!-- Records -->
        <div
          v-for="stableRef in stables"
          :key="stableRef.stable.id"
        >
          <SidebarItem
            :link="{
              name: 'Records',
              icon: 'fa-solid fa-cabinet-filing text-orange',
              path: `/stable/${stableRef.stable.id}/records`
            }"
          />
        </div>

        <!-- Manage -->
        <sidebar-item
          :link="{
            name: 'Manage',
            icon: 'fas fa-tools text-purple' }"
        >
          <div
            v-for="stableRef in stables"
            :key="stableRef.stable.id"
          >
            <sidebar-item
              v-if="isAdminOfStable(stableRef)"
              :link="{
                name: 'Stable Info &amp; Settings',
                path: '/stable/' + stableRef.stable.id
              }"
            />
            <sidebar-item
              :link="{
                name: 'Equines',
                path: `/stable/${stableRef.stable.id}/equines`
              }"
              :badge="{
                show: pendingEquinesForStableRef(stableRef) > 0,
                text: pendingEquinesForStableRef(stableRef).toString(),
                type: 'danger'
              }"
            />
            <div v-if="isAdminOfStable(stableRef)">
              <sidebar-item
                :link="{
                  name: 'Users',
                  path: '/stable/' + stableRef.stable.id + '/users'
                }"
              />
              <sidebar-item
                :link="{
                  name: 'Event Products',
                  path: '/events' }"
              />
              <sidebar-item
                :link="{
                  name: 'Resources',
                  path: '/resources' }"
              />
            </div>
          </div>
        </sidebar-item>

        <!-- Need Help? -->
        <sidebar-item-alt
          :link="{
            name: 'Need Help?',
            icon: 'fa-solid fa-circle-question text-danger',
            hasAction: true,
            action: sendSupportEmail,
            path: '#'
          }"
        />

        <!-- Coming Soon -->
        <sidebar-item
          :link="{
            name: 'Coming Soon',
            icon: 'ni ni-watch-time text-gray'
          }"
        >
          <sidebar-item
            :link="{
              name: 'Messages',
              icon: 'fas fa-envelope text-blue'
            }"
          >
            <sidebar-item
              :disabled="true"
              :link="{ name: 'Client Inbox', path: '#' }"
            />
            <sidebar-item
              :disabled="true"
              :link="{ name: 'Team Inbox', path: '#' }"
            />
          </sidebar-item>

          <!-- Directory -->
          <sidebar-item
            :link="{
              name: 'Directory',
              icon: 'ni ni-single-copy-04 text-purple'
            }"
          >
            <sidebar-item
              :disabled="true"
              :link="{ name: 'Clients', path: '#' }"
            />
          </sidebar-item>
          <sidebar-item
            :disabled="true"
            :link="{
              name: 'Emergency Contacts & Information',
              icon: 'fas fa-address-book text-red',
              path: '#'
            }"
          />
        </sidebar-item>
      </template>

      <!--<template slot="links-after">-->
      <!--  <hr class="my-3"/>-->
      <!--  <h6 class="navbar-heading p-0 text-muted">Documentation</h6>-->

      <!--  <ul class="navbar-nav mb-md-3">-->
      <!--    <li class="nav-item">-->
      <!--      <a-->
      <!--        class="nav-link"-->
      <!--        href="https://demos.creative-tim.com/vue-argon-dashboard-pro/documentation"-->
      <!--        target="_blank"-->
      <!--      >-->
      <!--        <i class="ni ni-spaceship"></i>-->
      <!--        <span class="nav-link-text">Getting started</span>-->
      <!--      </a>-->
      <!--    </li>-->

      <!--  </ul>-->
      <!--</template>-->
    </side-bar-component>
    <div class="main-content">
      <dashboard-navbar
        v-if="!isFullScreenMode"
        :type="$route.meta.navbarType"
      />

      <div :class="!isFullScreenMode ? 'mt-3' : ''">
        <fade-transition
          :duration="200"
          origin="center top"
          mode="out-in"
        >
          <!-- your content here -->
          <router-view />
        </fade-transition>
      </div>

      <content-footer
        v-if="!$route.meta.hideFooter && !isFullScreenMode"
      />
    </div>
  </div>
</template>

<script>
/* eslint-disable no-new */
import PerfectScrollbar from 'perfect-scrollbar'
import 'perfect-scrollbar/css/perfect-scrollbar.css'
import DashboardNavbar from './DashboardNavbar.vue'
import SidebarUser from '../../components/Custom/SidebarUser.vue'
import ContentFooter from './ContentFooter.vue'
import { FadeTransition } from 'vue2-transitions'

function hasElement (className) {
  return document.getElementsByClassName(className).length > 0
}

function initScrollbar (className) {
  if (hasElement(className)) {
    new PerfectScrollbar(`.${className}`)
  } else {
    // try to init it later in case this component is loaded async
    setTimeout(() => {
      initScrollbar(className)
    }, 100)
  }
}

export default {
  components: {
    DashboardNavbar,
    ContentFooter,
    FadeTransition,
    SidebarUser,
    // SidebarHorses
  },
  data () {
    return {
      // Populated from Firebase RemoteConfig (mounted)
      showBoarders: false,
      showProducts: false,
    }
  },
  computed: {
    isFullScreenMode () {
      // get full=true from route query
      return this.$route.query.full === 'true'
    },
    stables () {
      return this.$store.getters['stables/stables']
    },
    adminOfStables () {
      return this.stables.filter(this.isAdminOfStable)
    },
    stableLogo () {
      let stableAdmin =
        this.adminOfStables.length > 0
          ? this.adminOfStables[0].stable
          : undefined
      if (stableAdmin && stableAdmin.logo) {
        return stableAdmin.logo.path
      }
      let stable = this.stables.length > 0 ? this.stables[0].stable : undefined
      if (stable && stable.logo) {
        return stable.logo.path
      }
      return 'logo/gray_green_barn.svg'
    },
    pendingEquines () {
      let equines = this.$store.getters['equines/equines']
      return equines.filter(equine => {
        return equine.stables.some(stable => stable.stableAccepted == null || stable.ownerAccepted == null)
      })
    },
  },
  async mounted () {
    this.initScrollbar()
    await this.boardFeatureFlag()
    await this.productsFeatureFlag()
  },
  methods: {
    initScrollbar () {
      let isWindows = navigator.platform.startsWith('Win')
      if (isWindows) {
        initScrollbar('sidenav')
      }
    },
    async boardFeatureFlag () {
      this.showBoarders = await this.$firebase.getRemoteBoolean('enable_board')
    },
    async productsFeatureFlag () {
      this.showProducts = this.$firebase.getRemoteBoolean('enable_products')
    },
    sendSupportEmail () {
      let x = window.open('mailto:help@haynet.horse', '_self')
      x.close()
    },
    // filterAdminStables (stables) {
    //   return stables.filter(this.isAdminOfStable())
    // },
    isAdminOfStable (stableRef) {
      return this.$store.getters['user/isAdminOfStable'](stableRef)
    },
    isAdminOrTrainerOfStable (stableRef) {
      return this.$store.getters['user/isTrainerOrAdminOfStable'](stableRef)
    },
    pendingEquinesForStableRef (stableRef) {
      let id = stableRef.stable.id
      return this.pendingEquines.filter(equine => {
        return equine.stables.some(stable => stable.stableId === id)
      }).length
    },
  },
}
</script>

<style lang="scss">
.fa-user-circle {
  color: #0cbcff;
}
</style>
