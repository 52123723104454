import ClickOutside from '../directives/ClickOutside.js'
import OutsideClick from '../directives/OutsideClick.js'

/**
 * You can register global directives here and use them as a plugin in your main Vue instance
 */

const GlobalDirectives = {
  install (Vue) {
    Vue.directive('click-outside', ClickOutside)
    Vue.directive('outside-click', OutsideClick)
  },
}

export default GlobalDirectives
