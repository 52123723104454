//previously the starterRouter from the template.
//using the router format with a separate routes file.
import Vue from 'vue'
import VueRouter from 'vue-router'
import routes from './hayNetRoutes'
import _ from 'lodash'
import NProgress from 'nprogress'

Vue.use(VueRouter)

// configure router
const router = new VueRouter({
  /* History mode presents a normal URL, the default is hash mode
  which adds # notation to the URL to make single page routing easy
  see router section in README for more info */
  mode: 'history',
  hash: true,
  routes: routes, // short for routes: routes
  linkActiveClass: 'active',
  scrollBehavior: (to, from, savedPosition) => {
    if (savedPosition) {
      return savedPosition
    }
    // If the name and params are the same we don't need to scroll
    if (from && to.name === from.name && _.isEqual(to.params, from.params)) {
      return
    }
    if (to.hash) {
      // return {el: to.hash, behavior: 'smooth'}
      return { selector: to.hash, behavior: 'smooth' }
    }
    return { x: 0, y: 0 }
  },
})

router.beforeResolve((to, from, next) => {
  // If this isn't an initial page load.
  if (to.name) {
    // Start the route progress bar.
    NProgress.start()
    NProgress.set(0.1)
  }
  next()
})

router.afterEach(() => {
  // Complete the animation of the route progress bar.
  NProgress.done()
})

export default router
