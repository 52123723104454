<template>
  <div
    class="card"
    :class="[
      {'card-lift--hover': hover},
      {'shadow': shadow},
      {[`shadow-${shadowSize}`]: shadowSize},
      {[`bg-gradient-${gradient}`]: gradient},
      {[`bg-${type}`]: type}
    ]"
  >
    <slot name="image" />
    <div
      v-if="$slots.header"
      class="card-header"
      :class="headerClasses"
    >
      <slot name="header" />
    </div>
    <div
      v-if="!noBody"
      class="card-body"
      :class="bodyClasses"
    >
      <slot />
    </div>

    <slot v-if="noBody" />

    <div
      v-if="$slots.footer"
      class="card-footer"
      :class="footerClasses"
    >
      <slot name="footer" />
    </div>
  </div>
</template>
<script>
export default {
  name: 'CardComponent',
  props: {
    type: {
      type: String,
      description: 'Card type',
      default: undefined,
    },
    gradient: {
      type: String,
      description: 'Card background gradient type (warning,danger etc)',
      default: undefined,
    },
    hover: {
      type: Boolean,
      description: 'Whether card should move on hover',
    },
    shadow: {
      type: Boolean,
      description: 'Whether card has shadow',
      default: true,
    },
    shadowSize: {
      type: String,
      description: 'Card shadow size',
      default: undefined,
    },
    noBody: {
      type: Boolean,
      default: false,
      description: 'Whether card should have wrapper body class',
    },
    bodyClasses: {
      type: [String, Object, Array],
      description: 'Card body css classes',
      default: undefined,
    },
    headerClasses: {
      type: [String, Object, Array],
      description: 'Card header css classes',
      default: undefined,
    },
    footerClasses: {
      type: [String, Object, Array],
      description: 'Card footer css classes',
      default: undefined,
    },
  },
}
</script>
<style>
</style>
