import { extend } from 'vee-validate'
import * as rules from 'vee-validate/dist/rules'
import localeEn from 'vee-validate/dist/locale/en.json'
import { parsePhoneNumber } from 'awesome-phonenumber'


// https://github.com/logaretm/vee-validate/issues/2698
Object.keys(rules).forEach(rule => {
  extend(rule, {
    ...rules[rule], // copies rule configuration
    message: localeEn.messages[rule], // assign message
  })
})

extend('year', {
  validate (value) {
    return new Promise(resolve => {
      resolve({ valid: value >= 1900 && value.length === 4 })
    })
  },
  message (fieldName) {
    return `${fieldName} is not valid year`
  },
})

extend('phone', {
  validate (value) {
    return new Promise(resolve => {
      let phone = parsePhoneNumber(value, { regionCode: 'US' })
      resolve({ valid: phone.valid })
    })
  },
  message (fieldName) {
    return `${fieldName} is not valid phone number`
  },
})
