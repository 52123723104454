import BaseInput from '../components/Inputs/BaseInput.vue'
import CardComponent from '../components/Cards/CardComponent.vue'
import ModalComponent from '../components/ModalComponent.vue'
import BaseButton from '../components/BaseButton.vue'
import BadgeComponent from '../components/BadgeComponent.vue'
import BaseCheckbox from '../components/Inputs/BaseCheckbox.vue'
import BaseRadio from '@components/Inputs/BaseRadio.vue'
import BaseAlert from '@components/BaseAlert.vue'
import BaseNav from '@components/Navbar/BaseNav.vue'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { Input, Tooltip, Popover } from 'element-ui'
import ValidatedPassword from '../components/Inputs/ValidatedPassword.vue'
import ImgixImg from '../components/ImgixImg.vue'
import Scrollspy from 'vue2-scrollspy'
// import VueYoutube from 'vue-youtube'
// import VueParticles from 'vue-particles'

import VueScrollTo from 'vue-scrollto'
/**
 * You can register global components here and use them as a plugin in your main Vue instance
 */

const GlobalComponents = {
  install (Vue) {
    Vue.component(BadgeComponent.name, BadgeComponent)
    Vue.component(BaseAlert.name, BaseAlert)
    Vue.component(BaseButton.name, BaseButton)
    Vue.component(BaseCheckbox.name, BaseCheckbox)
    Vue.component(BaseInput.name, BaseInput)
    Vue.component(BaseNav.name, BaseNav)
    Vue.component(BaseRadio.name, BaseRadio)
    Vue.component(CardComponent.name, CardComponent)
    Vue.component(ModalComponent.name, ModalComponent)
    Vue.component(Input.name, Input)
    Vue.component(ImgixImg.name, ImgixImg)
    Vue.component('ValidationProvider', ValidationProvider)
    Vue.component('ValidationObserver', ValidationObserver)
    Vue.component(ValidatedPassword.name, ValidatedPassword) // todo: scope this later?
    Vue.use(Tooltip)
    Vue.use(Popover)
    Vue.use(VueScrollTo)
    Vue.use(Scrollspy)
  },
}

export default GlobalComponents
