import moment from 'moment-timezone'

const DAYS_OF_WEEK = ['monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday', 'sunday']

function dayAsNumber (dayString) {
  return dayIndex(dayString) + 1
}

function dayIndex (dayString) {
  return DAYS_OF_WEEK.indexOf(dayString.toLowerCase())
}

function getDayDistance (startDayAsNumber, endDayAsNumber) {
  const lhs = startDayAsNumber
  const rhs = endDayAsNumber
  let distance = 0
  if (lhs === rhs) return distance
  if (lhs > rhs) {
    // Find the distance between lhs and end of week.
    const distanceToEnd = 7 - lhs
    distance = distanceToEnd + rhs
  } else {
    distance = rhs - lhs
  }
  return distance
}

/**
 * Uses the currently set range start for the given day of the week and calculates the first occurrence
 * of the given day of the week.
 *
 * @param state
 * @param dayOfWeekStr
 * @param startTime
 * @param isAllDay
 * @returns {*}
 */
function getWindowStart (state, dayOfWeekStr, startTime, isAllDay) {
  const rangeStart = state.schedule.rangeStart
  if (rangeStart === undefined) {
    console.log('Range Start has not been defined.')
    return
  }
  let mmt = moment(rangeStart)
  if (!isAllDay) {
    setTimeFromDateTime(mmt, startTime)
  } else {
    mmt.hours(0)
    mmt.minutes(0)
    mmt.seconds(0)
  }

  const rangeStartWeekDay = mmt.isoWeekday()
  const distance = getDayDistance(rangeStartWeekDay, dayAsNumber(dayOfWeekStr))
  const windowStart = mmt.add(distance, 'days')

  return windowStart.toDate()
}

function getWindowEnd (state, dayOfWeekStr, startTime, endTime, isAllDay) {
  // Ensure we have the correct start in case the calculated start was not given here.
  const startDateTime = getWindowStart(state, dayOfWeekStr, startTime)
  // Fetch the date from startTime and apply end time.
  let endMoment = moment(startDateTime)

  if (!isAllDay) {
    setTimeFromDateTime(endMoment, endTime) // selected endTime for day window
  } else {
    // Use start date set on the moment and set the hrs, mins, secs to the end time of same day.
    endMoment.hours(11)
    endMoment.minutes(59)
    endMoment.seconds(59)
  }

  return endMoment.toDate()
}

/**
 * Mutates the time on the moment object given to match the time set on the dateTime object.
 * @param mmt
 * @param dateTime
 * @returns {*}
 */
function setTimeFromDateTime (mmt, dateTime) {
  // strip off the range end
  const timeMoment = moment(dateTime)
  const hour = timeMoment.hours()
  const minute = timeMoment.minutes()
  mmt.hours(hour)
  mmt.minutes(minute)
  mmt.seconds(0)
  return mmt
}

/**
 * Sets the time to midnight and formats the date to ISO format without milliseconds.
 * @param dateTime
 * @returns {string}
 */
function formatISODateTimeForAllDay (dateTime) {
  let updated = moment(dateTime)
  updated.hours(0)
  updated.minutes(0)
  updated.seconds(0)
  return updated.toISOString().replace(/.\d+Z$/g, 'Z') // ('YYYY-MM-DDTHH:mm:ss[Z]')
}

function createWindow (
  state, instanceId, windowId, dayOfWeekStr, startDateTime, endDateTime, isAllDay, isRecurring, recurrenceRules,
) {
  if (state.schedule.rangeStart == null) {
    console.log('Error: Range Start has not been defined.')
    return
  }

  let isAllDayBusinessHrs = false

  // if (isAllDay) {
  //   if (startDateTime == null && endDateTime == null) {
  //     const timeMoment = moment()
  //     timeMoment.hours(0)
  //     timeMoment.minutes(0)
  //     startTime = endTime = timeMoment.toDate()
  //     console.log("createWindow() allDAY = start " + timeMoment.toDate())
  //   } else {
  //     // todo get recurring stable hours and apply to window.
  //     isAllDayBusinessHrs = true
  //   }
  // }

  const windowStart = getWindowStart(state, dayOfWeekStr, startDateTime, isAllDay)
  const windowEnd = getWindowEnd(state, dayOfWeekStr, startDateTime, endDateTime, isAllDay)

  return {
    instanceId: instanceId,
    windowId: windowId,
    dayOfWeekStr: dayOfWeekStr,
    startDateTime: windowStart,
    endDateTime: windowEnd,
    timeZoneName: moment.tz.guess(),
    isAllDay: isAllDay,
    isAllDayBusinessHours: isAllDayBusinessHrs,
    isRecurring: isRecurring,
    recurrenceRules: recurrenceRules,
  }
}

function copyRecurrenceRulesToAnotherDay (state, { dayOfWeekStr, copyWindow, rangeStartDate }) {
  let recurrenceRules = null
  if (copyWindow.recurrenceRules != null) {
    const recurrenceTypeWeekly = 'WEEKLY'
    const recurrenceTypeMonthly = 'MONTHLY'
    const recurrenceTypeYearly = 'YEARLY'
    const oldRules = copyWindow.recurrenceRules
    const type = oldRules.recurrenceType
    // const windowStartMoment = moment(copyWindow.startDateTime)

    let recurringDayOfWeek
    if (type === recurrenceTypeWeekly) {
      recurringDayOfWeek = dayAsNumber(dayOfWeekStr)
    } else if (type === recurrenceTypeMonthly && oldRules.recurrenceDayOfWeek != null) {
      recurringDayOfWeek = dayAsNumber(dayOfWeekStr)
    } else {
      recurringDayOfWeek = null
    }

    let recurringDayOfMonth
    if (type === recurrenceTypeMonthly && oldRules.recurrenceDayOfMonth != null || type === recurrenceTypeYearly) {
      const startDate = getWindowStart(state, dayOfWeekStr, rangeStartDate, copyWindow.isAllDay)
      recurringDayOfMonth = moment(startDate).date()//windowStartMoment.month()
    } else {
      recurringDayOfMonth = null
    }

    let recurringMonthOfYear
    if (type === recurrenceTypeYearly) {
      const startDate = getWindowStart(state, dayOfWeekStr, rangeStartDate, copyWindow.isAllDay)
      recurringMonthOfYear = moment(startDate).month() + 1 // moment month() is 0 based (0-11).
    } else {
      recurringMonthOfYear = null
    }

    recurrenceRules =
      {
        isRecurring: oldRules.isRecurring,
        selectedOptionValue: oldRules.selectedOptionValue,
        recurrenceType: type,
        recurrenceSeparation: oldRules.recurrenceSeparation,
        recurrenceDayOfWeek: recurringDayOfWeek,
        recurrenceWeekOfMonth: oldRules.recurrenceWeekOfMonth,
        recurrenceDayOfMonth: recurringDayOfMonth,
        recurrenceMonthOfYear: recurringMonthOfYear,
        recurrenceEndDate: oldRules.recurrenceEndDate,
        recurrenceCount: oldRules.recurrenceCount,
      }
  }
  return recurrenceRules
}

function convertToCents (amountInDollars) {
  return amountInDollars * 100
}

function convertToDollars (amountInCents) {
  if (amountInCents < 1) return 0
  else return amountInCents / 100
}

export {
  dayIndex,
  dayAsNumber,
  getDayDistance,
  createWindow,
  getWindowStart,
  getWindowEnd,
  copyRecurrenceRulesToAnotherDay,
  convertToCents,
  convertToDollars,
  formatISODateTimeForAllDay,
}
