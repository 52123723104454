// const imgixCdnConfigs = {
//   devConfig: {
//     cdnOptions: {
//       domains: 'dev-haynet.imgix.net'
//     },
//     sourceDomain: 'https://bucket.dev.haynet.co/haynet-public/'
//   },
//   prodConfig: {
//     cdnOptions: {
//       domains: 'haynet.imgix.net'
//     },
//     sourceDomain: 'https://img-haynet-horse.nyc3.digitaloceanspaces.com'
//   }
// }

// we need to do this, so that imgix is a globally defined variable
import 'imgix.js'

export default {
  install (Vue) {
    window.imgix.config.host = import.meta.env.VITE_HAYNET_IMGIX
    Vue.prototype.$imgix = window.imgix
  },
}
