<template>
  <div class="wrapper">
    <notifications-component />
    <base-nav
      id="top-navbar"
      v-model="showMenu"
      :transparent="true"
      menu-classes="justify-content-end"
      class="navbar navbar-dark navbar-expand-lg fixed-top navbar-custom sticky navbar-main"
      expand="lg"
    >
      <!--      <nav class="navbar navbar-expand-lg fixed-top navbar-custom sticky sticky-dark" id="navbar">-->
      <div
        v-if="showBrand"
        slot="brand"
        class="navbar-wrapper"
      >
        <a
          v-if="isWelcomePage"
          v-scroll-to="{ el:'#brand'}"
          class="nav-link"
          data-scroll-spy-id="brand"
          href="javascript: void(0);"
        ><img
          src="/img/brand/haynet_text_only_white.svg"
          style="height: 40px"
        ></a>
        <router-link
          v-else
          class="nav-item"
          to="/welcome"
        >
          <img
            src="/img/brand/haynet_text_only_white.svg"
            style="height: 40px"
          >
        </router-link>
      </div>

      <template>
        <div
          v-if="showBrand"
          class="navbar-collapse-header"
        >
          <div class="row">
            <div class="col-6 collapse-brand">
              <router-link to="/">
                <img
                  src="/img/brand/haynet_text_only_white.svg"
                  style="height: 40px"
                >
              </router-link>
            </div>
            <div class="col-6 collapse-close">
              <button
                type="button"
                class="navbar-toggler"
                @click="showMenu = false"
              >
                <span />
                <span />
              </button>
            </div>
          </div>
        </div>

        <!-- Navigation links -->
        <div class="row pl-3">
          <ul
            class="navbar-nav align-items-lg-center ml-lg-auto"
          >
            <!-- Info/Welcome -->
            <li class="nav-item">
              <a
                v-if="isWelcomePage"
                v-scroll-to="{ el:'#info'}"
                class="nav-link"
                data-scroll-spy-id="info"
                href="javascript: void(0);"
                @click="showMenu = false"
              >Info</a>
              <router-link
                v-else
                v-scroll-to="{ el:'#info'}"
                to="/welcome"
                class="nav-link"
                data-scroll-spy-id="info"
              >
                <span class="nav-link-inner--text">Info</span>
              </router-link>
            </li>
            <!-- Features-->
            <li class="nav-item">
              <!-- TODO: Fix this so that it works with the router -->
              <!--              <router-link-->
              <!--                :to="{ name: 'Welcome', hash: '#features' }"-->
              <!--                class="nav-link"-->
              <!--              >-->
              <!--                Features!!!-->
              <!--              </router-link>-->

              <a
                v-if="isWelcomePage"
                v-scroll-to="{ el:'#features'}"
                class="nav-link"
                data-scroll-spy-id="features"
                data-toggle="collapse"
                href="javascript: void(0);"
                @click="showMenu = false"
              >Features</a>
              <router-link
                v-else
                to="/welcome"
                class="nav-link"
                data-scroll-spy-id="features"
              >
                <span class="nav-link-inner--text">Features</span>
              </router-link>
            </li>
            <!-- Pricing -->
            <li class="nav-item">
              <a
                v-if="isWelcomePage"
                v-scroll-to="{ el:'#pricing'}"
                class="nav-link"
                data-scroll-spy-id="pricing"
                href="javascript: void(0);"
                @click="showMenu = false"
              >Pricing</a>
              <router-link
                v-else
                to="/welcome#pricing"
                class="nav-link"
              >
                <span class="nav-link-inner--text">Pricing</span>
              </router-link>
            </li>
            <!-- About -->
            <li class="nav-item">
              <a
                v-if="isWelcomePage"
                v-scroll-to="{ el:'#about'}"
                class="nav-link"
                data-scroll-spy-id="about"
                href="javascript: void(0);"
                @click="showMenu = false"
              >About</a>
              <router-link
                v-else
                to="/welcome#about"
                class="nav-link"
              >
                <span class="nav-link-inner--text">About</span>
              </router-link>
            </li>

            <!-- Authenticated -->
            <template v-if="isAuthenticated">
              <!-- Dashboard -->
              <li class="nav-item">
                <router-link
                  to="/dashboard"
                  class="nav-link"
                >
                  <span class="nav-link-inner--text">Dashboard</span>
                </router-link>
              </li>
              <!-- Logout -->
              <li class="nav-item">
                <a
                  href="#"
                  class="nav-link"
                  @click.prevent="logout"
                >
                  <span class="nav-link-inner--text">Logout</span>
                </a>
              </li>
            </template>

            <!-- Not authenticated -->
            <template v-else>
              <!-- Login -->
              <li class="nav-item">
                <router-link
                  to="/login"
                  class="nav-link"
                >
                  <span class="nav-link-inner--text">Login</span>
                </router-link>
              </li>
              <!-- Register -->
              <li class="nav-item">
                <router-link
                  to="/register"
                  class="nav-link"
                >
                  <span class="nav-link-inner--text">Register</span>
                </router-link>
              </li>
            </template>
          </ul>
        </div>
      </template>
    </base-nav>

    <div class="main-content">
      <zoom-center-transition
        :duration="pageTransitionDuration"
        mode="out-in"
      >
        <router-view />
      </zoom-center-transition>
    </div>

    <footer
      id="footer-main"
      class="py-5"
    >
      <div class="container">
        <div class="row align-items-center justify-content-xl-between">
          <div class="col-xl-6">
            <div class="copyright text-center text-xl-left text-muted">
              © {{ year }} <a
                :href="homeRoute"
                class="font-weight-bold ml-1"
                target="_blank"
              >HayNet Ltd</a>
            </div>
          </div>
          <div class="col-xl-6">
            <ul class="nav nav-footer justify-content-center justify-content-xl-end">
              <li class="nav-item">
                <a
                  :href="helpRoute"
                  class="nav-link"
                  target="_blank"
                >Contact us</a>
              </li>
              <!--              <li class="nav-item">-->
              <!--                <a-->
              <!--                  :href="infoRoute"-->
              <!--                  class="nav-link"-->
              <!--                  target="_blank"-->
              <!--                >About Us</a>-->
              <!--              </li>-->
            </ul>
          </div>
        </div>
      </div>
    </footer>
  </div>
</template>

<script>
import {mapActions} from 'vuex'
import {BaseNav} from '@components'
import {ZoomCenterTransition} from 'vue2-transitions'

export default {
  components: {
    BaseNav,
    ZoomCenterTransition,
  },

  beforeRouteUpdate (to, from, next) {
    // Close the mobile menu first then transition to next page
    if (this.showMenu) {
      this.closeMenu()
      setTimeout(() => {
        next()
      }, this.menuTransitionDuration)
    } else {
      next()
    }
  },
  props: {
    backgroundColor: {
      type: String,
      default: 'black',
    },
  },
  data () {
    return {
      infoRoute: 'https://haynet.horse/pricing',
      homeRoute: 'https://haynet.horse',
      helpRoute: 'mailto:help@haynet.horse',
      showMenu: false,
      menuTransitionDuration: 250,
      pageTransitionDuration: 200,
      year: new Date().getFullYear(),
      pageClass: 'login-page',
    }
  },
  computed: {
    title () {
      return `${this.$route.name} Page`
    },
    showBrand () {
      return true
    },
    isWelcomePage () {
      return this.$route.name?.toLowerCase()?.includes('welcome') ?? false
    },

    isAuthenticated () {
      return this.$store.getters['user/isAuthenticated']
    },
  },
  watch: {
    $route: {
      immediate: true,
      handler: function () {
        this.updateBackground()
      },
    },
  },
  mounted: () => {
    window.onscroll = function () {
      onWindowScroll()
    }
    let navbar = document.getElementById('top-navbar')
    function onWindowScroll () {
      if (
        document.body.scrollTop > 40 ||
        document.documentElement.scrollTop > 40
      ) {
        navbar.style.backgroundColor = '#16B787'
        // navbar.style.padding = '10px'
      } else {
        navbar.style.backgroundColor = ''
        // navbar.style.padding = '20px'
      }
    }
  },
  beforeDestroy () {
    this.removeBackgroundColor()
  },
  methods: {
    ...mapActions({
      setLogout: 'user/setLogout',
    }),
    /**
     * Toggle menu
     */
    toggleMenu () {
      document.getElementById('navbarCollapse').classList.toggle('show')
    },

    toggleNavbar () {
      document.body.classList.toggle('nav-open')
      this.showMenu = !this.showMenu
    },
    closeMenu () {
      document.body.classList.remove('nav-open')
      this.showMenu = false
    },
    setBackgroundColor () {
      document.body.classList.add('bg-default')
    },
    removeBackgroundColor () {
      document.body.classList.remove('bg-default')
    },
    updateBackground () {
      if (!this.$route.meta.noBodyBackground) {
        this.setBackgroundColor()
      } else {
        this.removeBackgroundColor()
      }
    },
    logout () {
      this.$store.commit('stables/resetStore')
      this.$store.commit('equines/resetStore')
      this.$store.commit('schedule/resetStore')
      this.setLogout(this.$router)
    },
  },
}
</script>

<style lang="scss">
$scaleSize: 0.8;
@keyframes zoomIn8 {
  from {
    opacity: 0;
    transform: scale3d($scaleSize, $scaleSize, $scaleSize);
  }
  100% {
    opacity: 1;
  }
}

.main-content .zoomIn {
  animation-name: zoomIn8;
}

@keyframes zoomOut8 {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
    transform: scale3d($scaleSize, $scaleSize, $scaleSize);
  }
}

.main-content .zoomOut {
  animation-name: zoomOut8;
}
</style>
