// This is the equine store module

import _ from 'lodash'

export const equines = {
  namespaced: true,
  state: {
    equines: [],
  },
  mutations: {
    setEquines (state, payload) {
      state.equines.splice(0, state.equines.length, ...payload)
    },

    resetStore (state) {
      state.equines.splice(0)
    },
  },
  getters: {
    equines: (state) => state.equines,
    /**
     * Returns equines filtered by userId
     * @param state
     * @returns {function(*): T[]}
     */
    equinesByUserId: (state) => (userId) => {
      return state.equines.filter(e => _.some(e.users, { 'userId': userId }))
    },
    equinesByStableId: (state) => (stableId) => {
      return state.equines.filter(e => _.some(e.stables, { 'stableId': stableId }))
    },
  },
  actions: {
    async refreshEquines ({commit}, context) {
      await context.$API.Equines.getAll().then(result => {
        commit('setEquines', result.data.data)
      })
    },
  },
}
