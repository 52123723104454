// API Service for Records
function records (apiConfig) {
  return {


    getAllRecordsForPastTwoWeeks () {
      const rq = apiConfig.api.get('v1/records/search', {
        params: {
          period: 14,
        },
      })
      return apiConfig.processCall(rq)
    },

    getRecords (searchParams, page) {
      const rq = apiConfig.api.get('v1/records/search', {
        params: {
          period: searchParams.period,
          from: searchParams.from,
          to: searchParams.to,
          eventTypeId: searchParams.eventTypeId,
          equines: searchParams.equines,
          users: searchParams.users,
          tags: searchParams.tags,
          page: page,
        },
      })
      return apiConfig.processCall(rq)
    },
  }
}

export default records
