<template>
  <div
    v-outside-click="{
      exclude: ['sidebar-click-exclude'],
      handler: hideSidebar
    }"
    class="sidenav navbar navbar-vertical fixed-left navbar-expand-xs navbar-light bg-white"
    :data="backgroundColor"
    @mouseenter="$sidebar.onMouseEnter()"
    @mouseleave="$sidebar.onMouseLeave()"
  >
    <div
      ref="sidebarScrollArea"
      class="scrollbar-inner"
    >
      <div
        class="sidenav-header h-auto d-flex flex-column-reverse align-items-center"
      >
        <a
          class="navbar-brand m-1 p-0 w-100 d-flex flex-column align-items-center"
          href="#"
        >
          <ImgixImg
            class="navbar-brand-img w-50 mh-100"
            alt="Stable Logo"
            :path="logo"
            fit="clamp"
            height="200"
            width="200"
          />
        </a>
        <div
          class="sidenav-toggler d-none d-md-block pb-0 align-self-end"
          @click="minimizeSidebar"
        >
          <!-- Sidenav toggler -->
          <h2
            v-if="!$sidebar.isMinimized"
            class="text-dark"
          >
            <i class="fa fa-lock" />
          </h2>
          <h2
            v-else
            class="text-dark"
          >
            <i class="fa fa-unlock" />
          </h2>
          <!-- old Sidenav toggler
          <div class="ml-auto align-self-start">
            <div class="sidenav-toggler pb-0 d-none d-xl-block"
                :class="{'active': !$sidebar.isMinimized }"
                @click="minimizeSidebar">
              <div class="sidenav-toggler-inner">
                <i class="sidenav-toggler-line"></i>
                <i class="sidenav-toggler-line"></i>
                <i class="sidenav-toggler-line"></i>
              </div>
            </div>
          -->
        </div>
      </div>
      <slot />
      <div class="navbar-inner">
        <ul class="navbar-nav">
          <slot name="links">
            <sidebar-item
              v-for="(link, index) in sidebarLinks"
              :key="link.name + index"
              :link="link"
            >
              <sidebar-item
                v-for="(subLink, subIndex) in link.children"
                :key="subLink.name + subIndex"
                :link="subLink"
              />
            </sidebar-item>
          </slot>
        </ul>
        <slot name="links-after" />
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: 'SidebarComponent',
  provide () {
    return {
      autoClose: this.autoClose,
    }
  },
  props: {
    title: {
      type: String,
      default: 'HayNet',
      description: 'Sidebar title',
    },
    shortTitle: {
      type: String,
      default: 'HN',
      description: 'Sidebar short title',
    },
    logo: {
      type: String,
      default: '/img/demo-logo.png',
      description: 'Sidebar stable logo',
    },
    backgroundColor: {
      type: String,
      default: 'vue',
      validator: value => {
        let acceptedValues = [
          '',
          'vue',
          'blue',
          'green',
          'orange',
          'red',
          'primary',
        ]
        return acceptedValues.indexOf(value) !== -1
      },
      description:
        'Sidebar background color (vue|blue|green|orange|red|primary)',
    },
    sidebarLinks: {
      type: Array,
      default: () => [],
      description:
        'List of sidebar links as an array if you don\'t want to use components for these.',
    },
    autoClose: {
      type: Boolean,
      default: true,
      description:
        'Whether sidebar should autoclose on mobile when clicking an item',
    },
  },
  watch: {
    '$sidebar.isMinimized' (val) {
      this.$store.commit('ui/setSidebarCollapsed', val)
    },
  },
  mounted () {
    //this is the strangest thing to get the sidebar to work right. There may be a better way, but just setting isMinimize to the stored value doesen't work
    let collapsed = this.$store.getters['ui/sidebarCollapsed']
    this.$sidebar.isMinimized = true
    this.$sidebar.onMouseLeave()
    if (!collapsed) {
      //if the sidebar isn't collapsed (and is large) we should show it
      this.$sidebar.toggleMinimize()
      //v This works because the displaysidebar only works if the screen is small... it's dumb but it works for hiding the sidebar for small screens
      this.$sidebar.displaySidebar(false)
    }
  },
  beforeDestroy () {
    if (this.$sidebar.showSidebar) {
      this.$sidebar.showSidebar = false
    }
  },
  methods: {
    minimizeSidebar () {
      if (this.$sidebar) {
        this.$sidebar.toggleMinimize()
      }
    },
    hideSidebar () {
      this.$sidebar.displaySidebar(false)
    },
  },
}
</script>
