function stable (apiConfig) {
  return {
    getAll () {
      const rq = apiConfig.api.get('v1/stables')
      return apiConfig.processCall(rq)
    },
    createNew (data) {
      const rq = apiConfig.api.post('v1/stable', data)
      return apiConfig.processCall(rq)
    },
    /**
     * Update a current stable
     * @param stableId {string}
     * @param data {Object}
     * @returns {Promise}
     */
    putStable (stableId, data) {
      const rq = apiConfig.api.put('v1/stable/' + stableId, data)
      return apiConfig.processCall(rq)
    },
    updateAnnouncement (id, announcement) {
      let data = { announcement: announcement }
      const rq = apiConfig.api.put('v1/stable/' + id + '/announcement', data)
      return apiConfig.processCall(rq)
    },
    /**
     * Put the new shareable link.
     *
     * @param id {String} stable id
     * @param data {Object} shareableLinkName and shareableLinkEnabled
     * @returns {Promise<>}
     */
    putSharableLink (id, data) {
      const rq = apiConfig.api.put('v1/stable/' + id + '/link', data)
      return apiConfig.processCall(rq)
    },
    get (id) {
      const rq = apiConfig.api.get('v1/stable/' + id)
      return apiConfig.processCall(rq)
    },
    search (query) {
      const rq = apiConfig.api.get('v1/stables/search', { params: { query: query } })
      return apiConfig.processCall(rq)
    },
    /**
     * if the user registers via link we send this up, if valid and enabled, it will return the stable they have just
     * registered too.
     *
     * @param link {String}
     * @returns {Promise<>}
     */
    getSearchableLink (link) {
      const rq = apiConfig.api.get('v1/stables/link/' + link)
      return apiConfig.processCall(rq)
    },
    /**
     * Get all events for a specific stable.
     * @param stableId
     * @returns {Promise}
     */
    getEvents (stableId) {
      const rq = apiConfig.api.get('v1/stable/' + stableId + '/events')
      return apiConfig.processCall(rq)
    },
    getTrainers (id) {
      const rq = apiConfig.api.get('v1/stable/' + id + '/trainers')
      return apiConfig.processCall(rq)
    },
    getHorseById (stableId, horseId) {
      const rq = apiConfig.api.get(
        'v1/stable/' + stableId + '/horse/' + horseId,
      )
      return apiConfig.processCall(rq)
    },
    addHorseToStable (stableId, horseId) {
      const rq = apiConfig.api.post(
        'v1/stable/' + stableId + '/horse/' + horseId,
      )
      return apiConfig.processCall(rq)
    },
    removeHorseFromStable (stableId, horseId) {
      const rq = apiConfig.api.delete(
        'v1/stable/' + stableId + '/horse/' + horseId,
      )
      return apiConfig.processCall(rq)
    },
    getHorses (stableId) {
      const rq = apiConfig.api.get('v1/stable/' + stableId + '/horses')
      return apiConfig.processCall(rq)
    },
    /**
     * Get all the resources for a stable.
     *
     * @param stableId {UUID}
     * @return {Promise<AxiosResponse>}
     */
    getResources (stableId) {
      const rq = apiConfig.api.get('v1/stable/' + stableId + '/resources')
      return apiConfig.processCall(rq)
    },
    /**
     * Add a new resource
     *
     * @param stableId {UUID}
     * @param request {ResourceResponse}
     * @return {Promise<AxiosResponse>}
     */
    postResource (stableId, request) {
      const rq = apiConfig.api.post('v1/stable/' + stableId + '/resource', request)
      return apiConfig.processCall(rq)
    },
    /**
     * Update a resource
     *
     * @param stableId {UUID}
     * @param resourceId {UUID}
     * @param request {ResourceResponse}
     * @return {Promise<AxiosResponse>}
     */
    putResource (stableId, resourceId, request) {
      const rq = apiConfig.api.put('v1/stable/' + stableId + '/resource/' + resourceId, request)
      return apiConfig.processCall(rq)
    },
    /**
     * Delete a resource
     *
     * @param stableId {UUID}
     * @param resourceId {UUID}
     * @return {Promise<AxiosResponse>}
     */
    deleteResource (stableId, resourceId) {
      const rq = apiConfig.api.delete('v1/stable/' + stableId + '/resource/' + resourceId)
      return apiConfig.processCall(rq)
    },
  }
}

export default stable
