import apiConfig from './apiConfig'
import disciplines from './disciplines'
import equines from './equines'
import stable from './stable'
import tasks from './tasks'
import user from './user'
import files from './files'
import organisations from './organisations'
import events from './events'
import account from './account'
import records from './records'

export default {
  install (vue) {
    //pass vue instance to config and get object to pass to API groups
    const ApiConfig = apiConfig(vue.prototype)
    //create user API group passing config object
    const User = user(ApiConfig)
    const Stable = stable(ApiConfig)
    const Disciplines = disciplines(ApiConfig)
    const Equines = equines(ApiConfig)
    const Tasks = tasks(ApiConfig)
    const Files = files(ApiConfig)
    const Organisations = organisations(ApiConfig)
    const Events = events(ApiConfig)
    const Account = account(ApiConfig)
    const Records = records(ApiConfig)
    vue.prototype.$API = {
      User,
      Stable,
      Disciplines,
      Equines,
      Tasks,
      Files,
      Organisations,
      Events,
      Account,
      Records,
    }
  },
}
