<template>
  <sidebar-item-alt
    :link="{
      name: userName,
      image: userImage
    }"
  >
    <sidebar-item-alt
      :link="{ name: 'Profile &amp; Account', icon: 'fa-solid fa-user', path: '/profile' }"
    />
    <!--    <sidebar-item-alt-->
    <!--      :link="{-->
    <!--        name: 'Settings',-->
    <!--        icon: 'ni ni-settings-gear-65',-->
    <!--        path: '/settings',-->
    <!--        hide: true-->
    <!--      }"-->
    <!--    />-->
    <!--    <sidebar-item-alt-->
    <!--      :link="{-->
    <!--        name: 'Activity',-->
    <!--        icon: 'ni ni-calendar-grid-58',-->
    <!--        path: '/activity',-->
    <!--        hide: true-->
    <!--      }"-->
    <!--    />-->
    <!--    <sidebar-item-alt-->
    <!--      :link="{ name: 'Support', icon: 'ni ni-support-16', path: '/activity' }"-->
    <!--    />-->
    <hr class="mx-5 my-1">
    <sidebar-item-alt
      :link="{
        name: 'Logout',
        icon: 'fa-solid fa-person-running',
        hasAction: true,
        action: signOutUser
      }"
    />
  </sidebar-item-alt>
</template>
<script>
import { mapActions } from 'vuex'

export default {
  name: 'SidebarUser',
  data: function () {
    return {}
  },
  computed: {
    user () {
      return this.$store.getters['user/userData']
    },
    userName () {
      return this.user.firstName + ' ' + this.user.lastName
    },
    userImage () {
      return this.user.profileImage ? this.user.profileImage.path : '/img/horse-icon.svg'
    },
  },
  methods: {
    ...mapActions({
      setLogout: 'user/setLogout',
    }),
    signOutUser () {
      this.$store.commit('stables/resetStore')
      this.$store.commit('equines/resetStore')
      this.$store.commit('schedule/resetStore')
      this.setLogout(this.$router)
    },
  },
}
</script>
<style scoped></style>
