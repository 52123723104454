import { initializeApp } from 'firebase/app'
import {
  getAuth,
  onAuthStateChanged,
  onIdTokenChanged,
  signInWithEmailAndPassword,
  createUserWithEmailAndPassword,
  sendPasswordResetEmail,
  setPersistence,
  signInWithRedirect,
  signInWithPopup,
  getRedirectResult,
  signOut as signOutFirebase,
  updateProfile,
  GoogleAuthProvider,
  browserLocalPersistence,
  browserSessionPersistence,
} from 'firebase/auth'
import { getRemoteConfig, fetchAndActivate, getBoolean } from 'firebase/remote-config'

export default {
  install (vue) {
    const firebaseApp = initializeApp(config)
    const auth = getAuth(firebaseApp)
    const remoteConfig = getRemoteConfig(firebaseApp)
    if (import.meta.env.NODE_ENV === 'development') {
      remoteConfig.settings.minimumFetchIntervalMillis = 5 * 60 * 1000 // 5 minutes
    }
    remoteConfig.defaultConfig = defaultRemoteConfig
    onIdTokenChanged(auth, () => {
      console.log('need to refresh auth token')
    })
    // Init firebaseApp
    let promise = new Promise(resolve => {
      if (auth.currentUser) {
        resolve(true)
      } else {
        const unsubscribe = onAuthStateChanged(auth, function (user) {
          unsubscribe()
          if (user) {
            console.log('firebase attached')
            resolve(true)
          } else {
            console.log('firebase user logged out')
            resolve(false)
          }
        })
      }
    })
    let emailLogin = async (email, password, forgetMe = false) => {
      //attempt sign in, if success the user update will trigger
      let persist = browserLocalPersistence
      if (forgetMe) {
        // Set session if they want to be forgotten after closing browser
        persist = browserSessionPersistence
      }
      return await setPersistence(auth, persist).then(function () {
        // New sign-in will be persisted with session persistence.
        return signInWithEmailAndPassword(auth, email, password)
        //return promise from firebase signIn to be handled by caller
      })
    }
    let googleLogin = async (forgetMe = false) => {
      let persist = browserLocalPersistence
      if (forgetMe) {
        // Set session if they want to be forgotten after closing browser
        persist = browserSessionPersistence
      }
      return await setPersistence(auth, persist).then(function () {
        // New sign-in will be persisted with session persistence.
        let provider = new GoogleAuthProvider()
        if (import.meta.env.VITE_GOOGLE_POPUP) {
          return signInWithPopup(auth, provider)
        }
        return signInWithRedirect(auth, provider)
      })
    }
    /**
     * Call on page create to get the redirect result from login call
     * @return {Promise<*>}
     */
    let googleRedirectResult = async () => {
      return await getRedirectResult(auth)
    }
    let signOut = async () => {
      return await signOutFirebase(auth)
    }
    let resetPassword = async (emailAddress, rerouteURL) => {
      let actionCodeSettings = {
        url: rerouteURL,
        handleCodeInApp: false,
      }
      return sendPasswordResetEmail(
        auth,
        emailAddress,
        actionCodeSettings,
      )
    }
    let getIdToken = async () => {
      await promise
      return auth.currentUser.getIdToken()
    }

    vue.prototype.$firebase = {
      app: firebaseApp,
      auth: auth,
      remoteConfig: remoteConfig,
      user: async function () {
        await promise
        return auth.currentUser
      },
      getIdToken,
      emailLogin,
      googleLogin,
      googleRedirectResult,
      createUserWithEmailAndPassword,
      updateProfile,
      resetPassword,
      signOut,
      getRemoteBoolean: async function (key) {
        return await fetchAndActivate(remoteConfig)
          .then(() => Promise.resolve(getBoolean(remoteConfig, key)))
      },
    }
  },
}

const config = {
  apiKey: import.meta.env.VITE_FIREBASE_API_KEY,
  authDomain: import.meta.env.VITE_FIREBASE_AUTH_DOMAIN,
  databaseURL: import.meta.env.VITE_FIREBASE_DATABASE_URL,
  projectId: import.meta.env.VITE_FIREBASE_PROJECT_ID,
  storageBucket: import.meta.env.VITE_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: import.meta.env.VITE_FIREBASE_MESSAGING_SENDER_ID,
  appId: import.meta.env.VITE_FIREBASE_APP_ID,
}

const defaultRemoteConfig = {
  'enable_board': false,
  'enable_products': false,
}
