<template>
  <div>
    <!-- Header -->
    <div class="header bg-gradient-info py-7 py-lg-8 pt-lg-9">
      <div class="container">
        <div class="header-body text-center mb-7">
          <div class="row justify-content-center">
            <div class="px-5">
              <div class="img-center">
                <!--<ImgixImg-->
                <!--  host="static-haynet.imgix.net"-->
                <!--  path="404/donkey_lighter_brown.svg" fit="clamp"/>-->
                <img src="https://static.haynet.horse/404/donkey_light_brown.png">
              </div>
              <div class="p-4 text-center">
                <h1 class="text-light">
                  Oh, hi
                </h1>
                <h2 class="text-light">
                  It looks like you're lost
                </h2>
                <p class="text-lead text-white" />
                <p class="text-lead text-white">
                  The page was not found. Head back to the barn and try again.
                </p>
              </div>

              <router-link
                to="/dashboard"
                class="font-weight-bold text-white mt-5"
              >
                Go to dashboard
              </router-link>
            </div>
          </div>
        </div>
      </div>
      <div class="separator separator-bottom separator-skew zindex-100">
        <svg
          x="0"
          y="0"
          viewBox="0 0 2560 100"
          preserveAspectRatio="none"
          version="1.1"
          xmlns="http://www.w3.org/2000/svg"
        >
          <polygon
            class="fill-default"
            points="2560 0 2560 100 0 100"
          />
        </svg>
      </div>
    </div>
    <!-- Page content -->
  </div>
</template>

<script>

export default {
  name: 'NotFound',
}
</script>
