import moment from 'moment-timezone'

function user (apiConfig) {
  return {
    //userdata endpoint example
    getCurrentUserData () {
      //create API call request
      const rq = apiConfig.api.get('v1/account/me')
      //process api request and return promise to component layer
      return apiConfig.processCall(rq)
    },
    updateCurrentUserData (data) {
      //create API call request
      const rq = apiConfig.api.put('v1/account/me', data)
      //process api request and return  promise to component layer
      return apiConfig.processCall(rq)
    },
    /**
     * Saves the user billing settings, returns the updated user object
     * @param billingSettings
     * @return {Promise<AxiosResponse>}
     */
    saveBillingSettings (billingSettings) {
      //create API call request
      const rq = apiConfig.api.put('v1/account/me/billing', billingSettings)
      //process api request and return  promise to component layer
      return apiConfig.processCall(rq)
    },
    /**
     * Get client secret to SetupIntent
     * @param paymentMethodType
     * @return {Promise<AxiosResponse>}
     */
    postSetupIntentRequest (paymentMethodType) {
      const req = apiConfig.api.post('v1/account/me/setup_intent', {}, {
        params: {
          paymentMethodTypes: [paymentMethodType],
        },
      })
      return apiConfig.processCall(req)
    },
    /**
     * Remove setup intent from user
     * @param setupIntentId
     * @return {Promise<AxiosResponse>}
     */
    deleteSetupIntent (setupIntentId) {
      const rq = apiConfig.api.delete('v1/account/me/setup_intent/' + setupIntentId)
      return apiConfig.processCall(rq)
    },
    /**
     * Gets setup intents for user with payment methods if exists
     * @return {Promise<AxiosResponse>}
     */
    getSetupIntents () {
      const rq = apiConfig.api.get('v1/account/me/setup_intents')
      return apiConfig.processCall(rq)
    },
    /**
     * Gets all payment methods for user, will embed SetupIntents if they exist.
     *
     * @return {Promise<AxiosResponse>}
     */
    getPaymentMethods () {
      const rq = apiConfig.api.get('v1/account/me/payment_methods')
      return apiConfig.processCall(rq)
    },
    /**
     * Deletes a payment method for a user
     * @param paymentMethodId
     */
    deletePaymentMethod (paymentMethodId) {
      const rq = apiConfig.api.delete('v1/account/me/payment_method/' + paymentMethodId)
      return apiConfig.processCall(rq)
    },
    /**
     * Sets the passed in payment method as the default for the user.
     * @param paymentMethodId
     * @return {Promise<AxiosResponse>}
     */
    setDefaultPaymentMethod (paymentMethodId) {
      const rq = apiConfig.api.post('v1/account/me/payment_method/' + paymentMethodId + '/default')
      return apiConfig.processCall(rq)
    },
    postOrgAccept (orgId) {
      const rq = apiConfig.api.post(
        'v1/account/me/organisation/' + orgId + '/accept',
      )
      return apiConfig.processCall(rq)
    },
    postRelationshipAccept (userId, groupId) {
      const rq = apiConfig.api.post(
        'v1/users/' + userId + '/relationship/accept',
        {'groupId': groupId},
      )
      return apiConfig.processCall(rq)
    },
    deleteRelationshipDecline (userId, groupId) {
      const requestData = {'groupId': groupId}
      const rq = apiConfig.api.delete(
        'v1/users/' + userId + '/relationship/decline',
        {data: requestData},
      )
      return apiConfig.processCall(rq)
    },
    getEvents () {
      const rq = apiConfig.api.get('v1/account/me/events')
      return apiConfig.processCall(rq)
    },
    getStables () {
      const rq = apiConfig.api.get('v1/account/me/stables')
      return apiConfig.processCall(rq)
    },
    /**
     * Get request to pull all board options a user has access to
     */
    getAllBoard () {
      //const rq = apiConfig.api.get('v1/account/me/board')
      //return apiConfig.processCall(rq)
      return new Promise(resolve => {
        resolve({data: {data: []}})
      })
    },
    /**
     * @param monthYear {String} YYYY-MM-DD
     * @param invoiceStatuses {Array} array of invoice statuses to filter by
     * @param withPastDue {Boolean} include past due invoices regardless of filter
     * @returns {Promise} Promise that resolves to an array of all the
     *  user's invoices
     */
    getAllInvoices (monthYear = undefined, invoiceStatuses = undefined, withPastDue = true) {
      const rq = apiConfig.api.get(
        'v1/account/me/invoices',
        {
          params: {
            monthYear: monthYear,
            invoiceStatuses: invoiceStatuses,
            withPastDue: withPastDue,
          },
        })
      return apiConfig.processCall(rq)
    },
    /**
     * Pull the Stripe customer connected to the logged-in user or create one
     * if one doesn't exist
     */
    getCreateStripeCustomer () {
      //const rq = apiConfig.api.get('v1/account/me/customer-info')
      //return apiConfig.processCall(rq)
      return new Promise(resolve => {
        resolve({
          status: 200,
          data: {
            customerId: 'asdf',
            paymentMethod: undefined,
          },
        })
      })
    },
    /**
     * Endpoint for the user to purchase the product with specified ID
     */
    // buyProduct (paymentMethod, productId, savePaymentInfo = false) {
    buyProduct () {
      // const rq = apiConfig.api.post(`v1/account/me/product/${productId}/buy`, {
      //   paymentMethod,
      //   savePaymentInfo
      // })
      // return apiConfig.processCall(rq)
      return new Promise(resolve => {
        resolve({
          status: 201,
          data: {status: 'active'},
        })
      })
    },
    // cancelProductSubscription (productId) {
    cancelProductSubscription () {
      //no data object for this post request at this point. Could become a get or require they send password in body to verify
      // const rq = apiConfig.api.post(`v1/account/me/product/${productId}/cancel`, {})
      // return apiConfig.processCall(rq)
      return new Promise(resolve => {
        alert('To Be Implemented')
        resolve({
          status: 200,
          data: true,
        })
      })
    },
    // requestProductFromAdmin (productId) {
    requestProductFromAdmin () {
      // const rq = apiConfig.api.post(`v1/account/me/product/${productId}/request`, {})
      // return apiConfig.processCall(rq)
      return new Promise(resolve => {
        alert('To Be Implemented')
        resolve({
          status: 200,
          data: true,
        })
      })
    },
    /**
     * @param until Date object with until when we want results.
     * @returns {*}
     */
    getActivityFeed (until) {
      const rq = apiConfig.api.get('v1/account/me/activity', {
        params: {
          until: until,
          tz: moment.tz.guess(),
        },
      })
      return apiConfig.processCall(rq)
    },
    /**
     * Post request to verify phone number, this will send a text to the requested
     * phone number.
     *
     * @param phone {String} phone number to verify
     * @returns {Promise<Response<*>>} response will return true if already verified for this
     * user.
     */
    postPhoneRequest (phone) {
      const rq = apiConfig.api.post('v1/account/me/phone', {
        phone: phone,
      })
      return apiConfig.processCall(rq)
    },
    /**
     * Verify the number with the code that twilio sent to the phone.
     *
     * @param phone {String} phone number to verify
     * @param code {String} code that was sent to the users phone
     */
    putPhoneRequest (phone, code) {
      const rq = apiConfig.api.put('v1/account/me/phone', {
        phone: phone,
        code: code,
      })
      return apiConfig.processCall(rq)
    },
    postUserInvite (data) {
      const rq = apiConfig.api.post('v1/user/invite', data)
      return apiConfig.processCall(rq)
    },
    update (id, data) {
      const rq = apiConfig.api.put('v1/users/' + id, data)
      return apiConfig.processCall(rq)
    },
    get (id) {
      const rq = apiConfig.api.get('v1/users/' + id)
      return apiConfig.processCall(rq)
    },
    updateTasks (id, data) {
      const rq = apiConfig.api.put('v1/users/' + id + '/tasks', data)
      return apiConfig.processCall(rq)
    },
    getTasks (id) {
      const rq = apiConfig.api.get('v1/users/' + id + '/tasks')
      return apiConfig.processCall(rq)
    },
    updateDisciplines (id, data) {
      const rq = apiConfig.api.put('v1/users/' + id + '/disciplines', data)
      return apiConfig.processCall(rq)
    },
    deleteDisciplines (id, data) {
      const rq = apiConfig.api.delete('v1/users/' + id + '/disciplines', data)
      return apiConfig.processCall(rq)
    },
    getUserEquines (id) {
      const rq = apiConfig.api.get('v1/users/' + id + '/equines')
      return apiConfig.processCall(rq)
    },
    getUserSubscriptions (userId) {
      const rq = apiConfig.api.get('v1/users/' + userId + '/subscriptions')
      return apiConfig.processCall(rq)
    },
  }
}

export default user
