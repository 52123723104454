import moment from 'moment-timezone'

function events (apiConfig) {
  return {
    /*getAll() {
        const rq = apiConfig.api.get("v1/tasks")
        //process api request and return promise to component layer
        return apiConfig.processCall(rq)
      },
      get(id) {
        const rq = apiConfig.api.get("v1/tasks/" + id)
        //process api request and return promise to component layer
        return apiConfig.processCall(rq)
      },*/
    getAll () {
      const rq = apiConfig.api.post('v1/event')
      return apiConfig.processCall(rq)
    },
    createEvent (data, email = false) {
      const rq = apiConfig.api.post('v1/event', data, {
        params: { sendEmail: email },
      })
      return apiConfig.processCall(rq)
    },

    updateEvent (eventId, data, email = false) {
      const rq = apiConfig.api.put('v1/event/' + eventId, data, {
        params: { sendEmail: email },
      })
      return apiConfig.processCall(rq)
    },

    /**
     * Update the published status of the event.
     *
     * @param id {String}
     * @param data {Object}
     * @returns {Promise}
     */
    putEventPublished (id, data) {
      const rq = apiConfig.api.put('v1/event/' + id + '/publish', data)
      return apiConfig.processCall(rq)
    },
    /**
     * Will archive this event, generally you don't want this, you want to un publish it.
     * @see putEventPublished
     * @param id {String}
     * @returns {Promise}
     */
    deleteEvent (id) {
      const rq = apiConfig.api.delete('v1/event/' + id)
      return apiConfig.processCall(rq)
    },

    getEvent (id) {
      const rq = apiConfig.api.get('v1/event/' + id)
      return apiConfig.processCall(rq)
    },

    /**
     * Fetches all available event types.
     *
     * @returns {Promise<AxiosResponse>}
     */
    getEventTypes () {
      const rq = apiConfig.api.get('v1/events/types')
      return apiConfig.processCall(rq)
    },

    /**
     * Will return Schedule for anything you are part of.
     *
     * Will add filters for Events etc.
     * @param stableId {String}
     * @param fromDate
     * @param toDate
     * @param eventIds {any[]}
     * @param userIds {any[]}
     * @returns {Promise | Promise<any>}
     */
    getScheduleForDates (stableId, fromDate, toDate, eventIds, userIds) {
      const rq = apiConfig.api.get('v1/schedule', {
        params: {
          stableId: stableId,
          from: fromDate,
          to: toDate,
          tz: moment.tz.guess(),
          eventIds: eventIds,
          userIds: userIds,
        },
      })
      return apiConfig.processCall(rq)
    },
    getBookingSlots (eventId, date, trainerIds) {
      const rq = apiConfig.api.get('v1/schedule/book', {
        params: {
          eventId: eventId,
          date: date,
          tz: moment.tz.guess(),
          trainerIds: trainerIds,
        },
      })
      return apiConfig.processCall(rq)
    },
    // Send the slot back that you want to book (booking slots are uniquely generate for that user)
    postBookingSlots (bookingSlotData) {
      const rq = apiConfig.api.post(
        'v1/schedule/book', bookingSlotData,
      )
      return apiConfig.processCall(rq)
    },
    /**
     * Reschedule a current booking with a different slot.
     *
     * @param instanceId this is the instanceId you are replacing the new one with.
     * @param newBookingSlot this is the json booking slot you are picking.
     * @param equineId {String} optional equineId to book for.
     */
    putRescheduleSlot (instanceId, newBookingSlot) {
      const rq = apiConfig.api.put(
        'v1/schedule/reschedule/' + instanceId,
        newBookingSlot,
      )
      return apiConfig.processCall(rq)
    },
    /**
     * Cancel the booking, this will fail if you don't have permissions to that event.
     *
     * @param instanceId the instance you are trying to cancel, every schedule based object is backed, by an
     * `instanceId`.
     */
    declineBookingSlot (instanceId) {
      const rq = apiConfig.api.delete('v1/schedule/cancel/' + instanceId)
      return apiConfig.processCall(rq)
    },

    /**
     * Accept the booking, this will fail if you don't have permissions to that event.
     *
     * @param instanceId the instance you are trying to cancel, every schedule
     * based object is backed by an `instanceId`.
     */
    acceptBookingSlot (instanceId) {
      const rq = apiConfig.api.post('v1/schedule/accept/' + instanceId, {})
      return apiConfig.processCall(rq)
    },

    /**
     * Adds an instance to an event manually, if part of an event you need to
     * pass the `eventId`.
     * If it's a standalone instance you can pass null eventId, but you need
     * to pass `stableId` and `eventTypeId`.
     *
     * @param instance {Object}
     * @param sendEmail {Boolean}
     * @returns {Promise<>}
     */
    postInstance (instance, sendEmail = false) {
      const params = { sendEmail: sendEmail }
      let rq = apiConfig.api.post('v1/instance', instance, { params: params })
      return apiConfig.processCall(rq)
    },

    /**
     * Will diff the event and make necessary changes.
     *
     * @param instanceId {String}
     * @param instance {Object}
     * @param sendEmail {boolean} should we email changes
     * @returns {Promise<>}
     */
    updateInstance (instanceId, instance, sendEmail = true) {
      const rq = apiConfig.api.put(
        'v1/instance/' + instanceId, instance, {
          params: { sendEmail: sendEmail },
        },
      )
      return apiConfig.processCall(rq)
    },
    /**
     * Decline an instance, basically the same as decline from a client pov with email sending, this will decline just
     * you, the sender.
     *
     * @see decline instance (booking/calender/task etc)
     * @param instanceId {String}
     * @param sendEmail {Boolean}
     * @returns {Promise<>}
     */
    declineInstance (instanceId, sendEmail = true) {
      const rq = apiConfig.api.delete(
        'v1/instance/' + instanceId + '/decline', {
          params: { sendEmail: sendEmail },
        },
      )
      return apiConfig.processCall(rq)
    },
    /**
     * Cancel an instance, this will fully cancel the event for everyone.
     *
     * @param instanceId {String}
     * @param sendEmail {Boolean}
     * @returns {Promise<>}
     */
    cancelInstance (instanceId, sendEmail = true) {
      const rq = apiConfig.api.delete(
        'v1/instance/' + instanceId + '/cancel', {
          params: { sendEmail: sendEmail },
        },
      )
      return apiConfig.processCall(rq)
    },
    /**
     * Finalize an instance, this will finalize the event for everyone.
     */
    finalizeInstance (instanceId) {
      const rq = apiConfig.api.post('v1/instance/' + instanceId + '/finalize')
      return apiConfig.processCall(rq)
    },
    /**
     * Accept an instance, this will accept the event for you.
     */
    acceptInstance (instanceId) {
      const rq = apiConfig.api.post('v1/instance/' + instanceId + '/accept')
      return apiConfig.processCall(rq)
    },
  }
}

export default events
