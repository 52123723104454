function disciplines (apiConfig) {
  return {
    getAll () {
      const rq = apiConfig.api.get('v1/disciplines')
      return apiConfig.processCall(rq)
    },
    createNew (data) {
      const rq = apiConfig.api.post('v1/disciplines', data)
      return apiConfig.processCall(rq)
    },
    updateExisting (id, data) {
      const rq = apiConfig.api.put('v1/disciplines/' + id, data)
      return apiConfig.processCall(rq)
    },
  }
}
export default disciplines
