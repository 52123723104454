import SidebarComponent from './SideBarComponent.vue'
import SidebarItem from './SidebarItem.vue'
import SidebarItemAlt from './SidebarItemAlt.vue'

const SidebarStore = {
  showSidebar: false,
  sidebarLinks: [],
  isMinimized: false,
  breakpoint: 768,
  hovered: false,
  displaySidebar (value) {
    if (window.innerWidth > this.breakpoint) {
      //I am assuming this is to stop it from working on the larger windows... This is dumb.
      return
    }
    this.isMinimized = !value
    this.showSidebar = value
    let docClasses = document.body.classList
    if (value) {
      docClasses.add('g-sidenav-pinned')
      docClasses.add('g-sidenav-show')
      docClasses.remove('g-sidenav-hidden')
    } else {
      docClasses.add('g-sidenav-hidden')
      docClasses.remove('g-sidenav-pinned')
      docClasses.remove('g-sidenav-show')
    }
  },
  toggleMinimize () {
    this.isMinimized = !this.isMinimized
    let docClasses = document.body.classList
    if (this.isMinimized) {
      docClasses.add('g-sidenav-hidden')
      docClasses.remove('g-sidenav-pinned')
      docClasses.remove('g-sidenav-show')
    } else {
      docClasses.add('g-sidenav-pinned')
      docClasses.add('g-sidenav-show')
      docClasses.remove('g-sidenav-hidden')
    }
    if (this.hovered) {
      docClasses.add('g-sidenav-show')
    }
  },
  onMouseEnter () {
    this.hovered = true
    if (this.isMinimized) {
      document.body.classList.add('g-sidenav-show')
      document.body.classList.remove('g-sidenav-hidden')
    }
  },
  onMouseLeave () {
    this.hovered = false
    if (this.isMinimized) {
      let docClasses = document.body.classList
      docClasses.remove('g-sidenav-show')
      docClasses.add('g-sidenav-hide')
      setTimeout(() => {
        docClasses.remove('g-sidenav-hide')
        docClasses.add('g-sidenav-hidden')
      }, 300)
    }
  },
}

const SidebarPlugin = {
  install (Vue, options) {
    if (options && options.sidebarLinks) {
      SidebarStore.sidebarLinks = options.sidebarLinks
    }
    let app = new Vue({
      data: {
        sidebarStore: SidebarStore,
      },
    })
    Vue.prototype.$sidebar = app.sidebarStore
    Vue.component('SideBarComponent', SidebarComponent)
    Vue.component('SidebarItem', SidebarItem)
    Vue.component('SidebarItemAlt', SidebarItemAlt)
  },
}

export default SidebarPlugin
