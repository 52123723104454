//Stripped down copy of original routes with only what we need as we go along

import DashboardLayout from '../views/Layout/DashboardNavBarLayout.vue'
import AuthLayout from '../views/Layout/AuthLayout.vue'
// GeneralViews
import NotFound from '../views/GeneralViews/NotFoundPage.vue'
import router from './hayNetRouter.js'
import * as Sentry from '@sentry/vue'

const EquineAdd = () => import('../views/Pages/Equines/EquineAdd.vue')
const EquineList = () => import ('../views/Pages/Equines/EquineList.vue')
const EquineView = () => import('../views/Pages/Equines/EquineView.vue')
const EquineEdit = () => import('../views/Pages/Equines/EquineEdit.vue')
const EquineRegistry = () =>
  import('../views/Pages/Equines/Registry/EquineRegistry.vue')


// Pages -- Imported as webpack chunks using magic comments. Currently, using Argon convention.
const Welcome = () =>
  import('../views/Pages/Home/WelcomePage.vue')
const Login = () =>
  import('@views/Pages/LoginPage.vue')
const ForgotPassword = () =>
  import('@views/Pages/ForgotPassword.vue')
const PrivacyPolicy = () =>
  import('@views/Pages/PrivacyPolicy.vue')
const Register = () =>
  import('../views/Pages/RegisterPage.vue')
const Onboarding = () =>
  import('@views/Pages/OnboardingPage.vue')
const Dashboard = () =>
  import('../views/Dashboard/HayNetDashboard.vue')
const UserProfilePage = () =>
  import('../views/Pages/UserProfile/UserProfilePage.vue')
const UserAccountPage = () =>
  import('../views/Pages/account/UserAccountPage.vue')
const Calendar = () =>
  import('../views/Calendar/CalendarView.vue')
const Booking = () =>
  import('../views/Pages/Booking/BookingPage.vue')
const Events = () =>
  import('../views/Pages/Events/EventsPage.vue')
const CreateEvent = () =>
  import('../views/Pages/Events/CreateEventProduct.vue')
const EventsHome = () =>
  import('../views/Pages/Events/EventsHome.vue')
const Stable = () =>
  import('../views/Pages/Stable/StablePage.vue')
const StableHome = () =>
  import('../views/Pages/Stable/StableHome.vue')
const StableEquines = () =>
  import('../views/Pages/Stable/StableEquines.vue')
const StableUsers = () =>
  import('../views/Pages/Stable/StableUsers.vue')
const StableSubscriptionsPage = () =>
  import('../views/Pages/Stable/StableSubscriptionsPage.vue')
const ProductsServices = () =>
  import('../views/Pages/Stable/StableProductsPage.vue')
const StableBilling = () =>
  import('../views/Pages/account/AccountBillingPage.vue')
const RecordsPage = () =>
  import('../views/Pages/Records/RecordsPage.vue')
// Resources
const ResourcesPage = () => import('../views/Pages/Resources/ResourcesPage.vue')
const ResourcesList = () => import('@views/Pages/Resources/ResourcesHome.vue')

/* EnforceLogin is a method called on select routes to ensure that firebase
  has a current user. Otherwise, those routes are off limits and the user gets
  rerouted to the login page. The method is asynchronous to await resolution of
  the user promise making sure that firebase is fully loaded. This works fine with
  Vue's reactivity system.*/
async function EnforceLogin (to, from, next) {
  let usr = await router.app.$firebase.user()
  if (!usr) {
    //firebase doesn't have a user logged in
    console.log('redirecting to login...')
    //reroute to log in, passing a reroute parameter instead of storing to vueX
    next({ name: 'Login', params: { route: to } })
  } else {
    Sentry.setUser({ id: usr.uid })
    try {
      let userData = await getUserData()
      getStables()
      getEquines()
      if (!userData.onboardingCompleted) {
        console.log('redirecting to onboarding...')
        next({ path: '/onboarding', params: { route: to.path } })
      } else {
        //has an onboarded user, continue to intended route
        router.app.$cookies.remove('shareableLinkName')
        next()
      }
    } catch (err) {
      console.error(err)
      next('/404')
    }
  }
}

async function getUserData () {
  let userData = router.app.$store.getters['user/userData']
  if (Object.keys(userData).length === 0) {
    console.log('Refreshing user')
    let res = await router.app.$API.User.getCurrentUserData()
    router.app.$store.commit('user/setUserData', res.data.data)
    return res.data.data
  }
  return userData
}

function getStables () {
  let stables = router.app.$store.getters['stables/stables']
  if (Object.keys(stables).length === 0) {
    console.log('Refreshing Stables, fire and forget.')
    router.app.$API.User.getStables()
      .then(res => router.app.$store.commit(
        'stables/setStables',
        res.data.data,
      ))
      .catch(() => {
      })
  }
}

function getEquines () {
  let equines = router.app.$store.getters['equines/equines']
  if (Object.keys(equines).length === 0) {
    console.log('Refreshing Equines')
    router.app.$API.Equines.getAll()
      .then(res => router.app.$store.commit(
        'equines/setEquines',
        res.data.data,
      ))
      .catch(() => {
      })
  }
}

/*Argon came with the below routing structure. I opted to keep it though it isn't my
first choice. The authPages object defines a parent route with children routes. The
parent page (AuthLayout) acts as a template that the child routes get wrapped in. The
router first renders the parent page and the renders the child page inside of the router
slot in the parent page, depeinding on the provided route. The AuthLayout template is at
 src/views/Layout/AuthLayout.vue*/
let authPages = {
  path: '/',
  component: AuthLayout,
  name: 'Authentication',
  children: [
    {
      path: '/welcome',
      name: 'Welcome',
      component: Welcome,
    },
    {
      path: '/login',
      name: 'Login',
      component: Login,
      props: true,
    },
    {
      path: '/forgot-password',
      name: 'ForgotPassword',
      component: ForgotPassword,
      props: true,
    },
    {
      path: '/register/*',
      component: Register,
    },
    {
      path: '/register',
      name: 'Register',
      component: Register,
    },
    {
      path: '/privacy-policy',
      name: 'PrivacyPolicy',
      component: PrivacyPolicy,
    },
    {
      path: '/onboarding',
      name: 'Onboarding',
      component: Onboarding,
      props: true,
    },
    { path: '*', component: NotFound },
  ],
}
/* The dashboard layout with dashboard child pages. All dashboard pages have the enforce login */
let dashboards = {
  path: '/dashboard',
  component: DashboardLayout,
  children: [
    {
      path: '',
      name: 'Dashboard',
      component: Dashboard,
    },
    {
      path: '/profile',
      name: 'Profile',
      component: UserProfilePage,
    },
    {
      path: '/profile/account',
      name: 'Payment Account',
      component: UserAccountPage,
    },
    {
      path: '/account/billing',
      name: 'Account Billing',
      component: StableBilling,
    },
    {
      path: '/account/subscriptions',
      name: 'Account Subscriptions',
      component: StableSubscriptionsPage,
    },
    {
      path: '/schedule',
      name: 'Calendar',
      props: (route) => ({
        stableId: route.query.stableId ? route.query.stableId : undefined,
        userId: route.query.userId ? route.query.userId : undefined,
        eventId: route.query.eventId ? route.query.eventId : undefined,
        fullScreen: route.query.full === 'true' ?? false,
      }),
      component: Calendar,
    },
    // Don't change path without creating a redirect for email links
    {
      path: '/booking',
      name: 'Booking',
      component: Booking,
      props: (route) => ({
        eventId: route.query.eventId ? route.query.eventId : undefined,
        date: route.query.date ? new Date(route.query.date) : undefined,
        instanceId: route.query.instanceId ? route.query.instanceId : undefined,
      }),
    },
    {
      path: '/events',
      component: Events,
      beforeEnter: async (to, from, next) => {
        let admin = await router.app.$store.getters['user/adminOfOrgIds']
        if (admin.size > 0) return next()
        next({ path: '/dashboard' })
      },
      children: [
        { path: '', name: 'Events', component: EventsHome },
        {
          path: ':stableId/create',
          name: 'CreateEvent',
          component: CreateEvent,
          props: (route) => ({
            stableId: route.params.stableId,
            eventId: route.query.eventId ?? undefined,
          }),
        },
      ],
    },
    {
      path: '/resources',
      name: 'Resources',
      component: ResourcesPage,
      beforeEnter: async (to, from, next) => {
        let admin = await router.app.$store.getters['user/adminOfOrgIds']
        if (admin.size > 0) return next()
        next({ path: '/dashboard' })
      },
      children: [
        { path: '', name: 'Resources', component: ResourcesList },
      ],
    },
    {
      path: '/equines',
      component: StableEquines,
      children: [
        {
          path: '',
          name: 'MyEquinesList',
          component: EquineList,
        },
        {
          path: ':equineId',
          name: 'MyEquineView',
          props: true,
          component: EquineView,
        },
        {
          path: ':equineId/edit',
          name: 'MyEquineEdit',
          props: true,
          component: EquineEdit,
        },
      ],
    },
    {
      path: '/stable/:stableId',
      component: Stable,
      props: true,
      children: [
        {
          path: '', name: 'Stable',
          component: StableHome,
          props: true,
          beforeEnter: EnforceStable(true),
        },
        // Records
        {
          path: 'records',
          name: 'Records',
          props: true,
          beforeEnter: EnforceStable(false),
          component: RecordsPage,
        },
        {
          path: 'equines',
          props: true,
          beforeEnter: EnforceStable(false),
          component: StableEquines,
          children: [
            {
              path: '',
              name: 'EquinesList',
              props: true,
              component: EquineList,
            },
            {
              path: 'create',
              name: 'CreateEquine',
              props: true,
              component: EquineAdd,
            },
            {
              path: ':equineId',
              name: 'EquineView',
              props: true,
              component: EquineView,
            },
            {
              path: ':equineId/edit',
              name: 'EquineEdit',
              props: true,
              component: EquineEdit,
            },
            {
              path: ':equineId/edit/registry',
              name: 'CreateRegistry',
              props: true,
              component: EquineRegistry,
            },
            {
              path: ':equineId/edit/registry/:registryId',
              name: 'EditRegistry',
              props: true,
              component: EquineRegistry,
            },
            {
              path: ':equineId/registry/:registryId',
              name: 'EquineRegistry',
              props: true,
              component: EquineRegistry,
            },
          ],
        },
        {
          path: 'users',
          name: 'StableUsers',
          props: true,
          beforeEnter: EnforceStable(true),
          component: StableUsers,
        },
        {
          path: 'billing',
          name: 'Billing',
          props: true,
          component: StableBilling,
          beforeEnter: EnforceStable(false),
        },
        {
          path: 'subscriptions',
          name: 'StableSubscriptions',
          props: true,
          beforeEnter: EnforceStable(false),
          component: StableSubscriptionsPage,
        },
        {
          path: 'products',
          name: 'StableProducts',
          props: true,
          beforeEnter: EnforceStable(true),
          component: ProductsServices,
        },
      ],
    },
  ],
  beforeEnter: EnforceLogin,
}

/* The routes definition. Including the two template parent route definitions and a reroute
as the login page is the entry point for users.  */
const routes = [
  {
    path: '/',
    name: 'Home',
    beforeEnter: async (to, from, next) => {
      let usr = await router.app.$firebase.user()
      if (!usr) {
        next('/welcome')
      } else {
        next('/dashboard')
      }
    },

  },
  dashboards,
  authPages,
]

/**
 * Requires a valid stable and enforces if the user must be an admin to access.
 * @param admin true if the user must be be an admin, false otherwise.
 */
function EnforceStable (admin = true) {
  return async (to, from, next) => {
    try {
      let stableRef = router.app.$store.getters['stables/getStable'](to.params.stableId)
      if (!stableRef) {
        let res = await router.app.$API.User.getStables()
        router.app.$store.commit('stables/setStables', res.data.data)
        stableRef = router.app.$store.getters['stables/getStable'](to.params.stableId)
        if (!stableRef) next({ path: '/dashboard' })
      }
      if (admin && !router.app.$store.getters['user/isAdminOfStable'](stableRef)) {
        return next({ path: '/dashboard' })
      }
      next()
    } catch {
      next({ path: '/dashboard' })
    }
  }
}

export default routes
