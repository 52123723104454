function equines (apiConfig) {
  return {
    getAll () {
      const rq = apiConfig.api.get('v1/equines')
      return apiConfig.processCall(rq)
    },
    get (id) {
      const rq = apiConfig.api.get('v1/equine/' + id)
      return apiConfig.processCall(rq)
    },
    createNew (data) {
      const rq = apiConfig.api.post('v1/equine', data)
      return apiConfig.processCall(rq)
    },
    updateExisting (id, data) {
      const rq = apiConfig.api.put('v1/equine/' + id, data)
      return apiConfig.processCall(rq)
    },
    update (id, data) {
      const rq = apiConfig.api.put(`v1/equine/${id}`, data)

      return apiConfig.processCall(rq)
    },
    delete (equineId) {
      const rq = apiConfig.api.delete(`v1/equine/${equineId}`)

      return apiConfig.processCall(rq)
    },
    createRegistry (id, data) {
      const rq = apiConfig.api.post(`v1/equine/${id}/registry`, data)

      return apiConfig.processCall(rq)
    },
    getRegistries (id) {
      const rq = apiConfig.api.get(`/v1/equine/${id}/registries`)

      return apiConfig.processCall(rq)
    },
    getRegistry (equineId, registryId) {
      const rq = apiConfig.api.get(`/v1/equine/${equineId}/registry/${registryId}`)

      return apiConfig.processCall(rq)
    },
    putRegistry (equineId, registryId, data) {
      const rq = apiConfig.api.put(`/v1/equine/${equineId}/registry/${registryId}`, data)

      return apiConfig.processCall(rq)
    },
    deleteRegistry (equineId, registryId) {
      const rq = apiConfig.api.delete(`/v1/equine/${equineId}/registry/${registryId}`)
      return apiConfig.processCall(rq)
    },
    /**
     * Accept or requests a equine to join a stable.
     * @param equineId
     * @param stableId
     * @returns {Promise<AxiosResponse>}
     */
    acceptOrInviteStableRequest (equineId, stableId) {
      const rq = apiConfig.api.post(`v1/equine/${equineId}/request/stable/${stableId}`)
      return apiConfig.processCall(rq)
    },
    /**
     * Deny's and deletes a request for an equine to join a stable.
     *
     * @param equineId
     * @param stableId
     * @returns {Promise<AxiosResponse>}
     */
    deleteStableRequest (equineId, stableId) {
      const rq = apiConfig.api.delete(`v1/equine/${equineId}/request/stable/${stableId}`)
      return apiConfig.processCall(rq)
    },

    /**
     * Deleted a registry file
     * @param equineId
     * @param registryId
     * @param fileId
     * @return {Promise<AxiosResponse>}
     */
    deleteRegistryFile (equineId, registryId, fileId) {
      const rq = apiConfig.api.delete(`v1/equine/${equineId}/registry/${registryId}/file/upload/${fileId}`)
      return apiConfig.processCall(rq)
    },
  }
}

export default equines
