
function account (apiConfig) {
  return {
    /**
     * Get all board objects for all stables across the organization.
     * @param orgId
     * @returns {Promise<AxiosResponse>}
     */
    getOrgBoardServices (orgId) {
      return apiConfig.processCall(
        apiConfig.api.get('v1/organisations/' + orgId + '/boards'),
      )
    },

    /**
     * Creates a new board service under this organization's Id.
     * @param orgId
     */
    createBoardService (orgId, boardItem) {
      return apiConfig.processCall(
        apiConfig.api.post('v1/organisations/' + orgId + '/board', boardItem),
      )
    },

    /**
     * Update an existing board service.
     * @param orgId
     * @returns {Promise<AxiosResponse>}
     */
    updateBoardService (orgId, boardItem) {
      return apiConfig.processCall(
        apiConfig.api.put('v1/organisations/' + orgId + '/board', boardItem),
      )
    },
  }
}

export default account
