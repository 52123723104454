import axios from 'axios'

export default {
  install (vue) {
    // Set config defaults when creating the instance
    const instance = axios.create({
      baseURL: import.meta.env.VITE_HAYNET_API_BASE_URL,
    })
    const publicInstance = axios.create({
      baseURL: import.meta.env.VITE_HAYNET_API_BASE_URL,
    })
    //attache the JWT to the header of every API request (TODO: Make sure this is the intent)
    instance.interceptors.request.use(
      async config => {
        config.headers['Authorization'] = 'Bearer ' + (await vue.prototype.$firebase.getIdToken())
        return config
      },
      async error => {
        return Promise.reject(error)
      },
    )
    //attache Axios to vue at $http to resemble old vue request library
    vue.prototype.$http = instance
    vue.prototype.$publicHttp = publicInstance
  },
}

