<template>
  <div style="position: relative">
    <slot :show-password="showPassword" />
    <div
      class="reveal-password-icon"
      @click="showPassword = !showPassword"
    >
      <i :class="showPassword ? 'fa-eye-slash fa-solid fa-lg' : 'fa-eye fa-solid fa-lg'" />
    </div>
  </div>
</template>
<script>
export default {
  name: 'BaseInputPasswordContainer',
  data () {
    return {
      showPassword: false,
    }
  },
}
</script>
<style>
  .reveal-password-icon {
    position: absolute;
    right: 0.5em;
    top: 50%;
    transform: translateY(-50%);
    cursor: pointer;
    z-index: 5;
    color: #adb5bd;
    width: 30px;
    display: flex;
    justify-content: center;
  }
</style>