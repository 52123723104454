/*!

=========================================================
* Vue Argon Dashboard PRO - v1.2.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard
* Copyright 2019 Creative Tim (https://www.creative-tim.com)

* Coded by www.creative-tim.com

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import Vue from 'vue'
import * as Sentry from '@sentry/vue'
import DashboardPlugin from './plugins/dashboard-plugin'
import firebase from './plugins/firebase'
import App from './App.vue'
import api from './plugins/api'
import axios from './plugins/axios'
import Imgix from './plugins/imgix'
import VueCookies from 'vue-cookies'
import store from './store'
import VueGtag from 'vue-gtag'
import VueBrowserUpdate from 'vue-browserupdate'
import 'element-ui/lib/theme-chalk/index.css'

// router setup
import router from './routes/hayNetRouter'
// plugin setup
Vue.use(DashboardPlugin)

//browser compatibility notification
Vue.use(VueBrowserUpdate, {
  options: {
    required: { s: 10, i: 11, e: 15, c: 51, f: 54, o:38 }, // es6+ support
    text:
      'Your browser {brow_name} is unsupported for our app. Please use another browser or attempt an update.',
  },
})

//init firebase, now attached to $firebase on the vue instance prototype
Vue.use(firebase)

// Add $axios to vue instance.
Vue.use(axios)

//Add $cookies to vue instance for easy cookie manipulation
Vue.use(VueCookies)

// Imgix plugin?
Vue.use(Imgix)

//Add $API to vue instance to give structured/configured calling of the backend
Vue.use(api)

Vue.use(
  VueGtag,
  {
    config: {
      id:
        import.meta.env.NODE_ENV === 'production'
          ? 'UA-164546470-2'
          : 'UA-164546470-1',
    },
    appName: 'HayNet',
    pageTrackerScreenviewEnabled: true,
  },
  router,
)

Sentry.init({
  Vue,
  dsn: import.meta.env.NODE_ENV === 'production'
    ? 'https://e1213ca978664f01bb0b7b0908246dec@o384380.ingest.sentry.io/5215644'
    : '',
  integrations: [
    new Sentry.BrowserTracing({
      logErrors: import.meta.env.NODE_ENV !== 'production',
      routingInstrumentation: Sentry.vueRouterInstrumentation(router),
    }),
  ],
})

/* eslint-disable no-new */
new Vue({
  el: '#app',
  render: h => h(App),
  router,
  store,
})
