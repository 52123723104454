import cookie from 'vue-cookies'
import Vue from 'vue'
export const ui = {
  namespaced: true,
  state: {
    prefs: {},
  },
  mutations: {
    setSidebarCollapsed (state, payload) {
      Vue.set(state.prefs, 'sidebarCollapsed', payload)
      cookie.set('sidebar_collapsed', payload) //should structure inside of cookies better
    },
  },
  getters: {
    sidebarCollapsed (state) {
      return state.prefs.sidebarCollapsed
        ? state.prefs.sidebarCollapsed
        : cookie.get('sidebar_collapsed') === 'true'
    },
  },
}
