function tasks (apiConfig) {
  return {
    /*getAll() {
        const rq = apiConfig.api.get("v1/tasks")
        //process api request and return promise to component layer
        return apiConfig.processCall(rq)
      },
      get(id) {
        const rq = apiConfig.api.get("v1/tasks/" + id)
        //process api request and return promise to component layer
        return apiConfig.processCall(rq) 
      },*/
    createNew (data) {
      const rq = apiConfig.api.post('v1/tasks', data)
      return apiConfig.processCall(rq)
    },
    updateExisting (id, data) {
      const rq = apiConfig.api.put('v1/tasks/' + id, data)
      return apiConfig.processCall(rq)
    },
    markAsDone (id, data) {
      const rq = apiConfig.api.post('v1/tasks/' + id + '/done', data)
      return apiConfig.processCall(rq)
    },
  }
}
export default tasks
