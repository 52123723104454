<template>
  <div class="input_container">
    <ul class="text-sm">
      <li :class="{ isValid: containsValidNumberOfCharacters }">
        8 to 26 characters long
      </li>
      <li :class="{ isValid: containsAlpha }">
        Contains at least one letter
      </li>
      <li :class="{ isValid: containsNumber }">
        Contains at least one number
      </li>
      <li :class="{ isValid: containsOnlyValidCharacters }">
        Contains only
        <small>a-z, 0-9, @#$%^&*+=!.?</small>
      </li>
    </ul>

    <div
      class="checkmark-container"
      :class="{ showCheckmark: validPassword }"
    >
      <svg
        width="50%"
        height="50%"
        viewBox="0 0 140 100"
      >
        <path
          class="checkmark"
          :class="{ checked: validPassword }"
          d="M10,50 l25,40 l95,-70"
        />
      </svg>
    </div>

    <form role="form">
      <base-input-password-container v-slot="{ showPassword }">
        <base-input
          v-model="vPassword"
          alternative
          name="password"
          autocomplete="off"
          placeholder="password"
          prepend-icon="fa fa-lock-alt"
          ni-lock-circle-open
          :validated="validPassword"
          :type="showPassword ? 'text' : 'password'"
          @input="checkPassword"
        />
      </base-input-password-container>
      <base-input-password-container v-slot="{ showPassword }">
        <base-input
          v-model="confirmPassword"
          alternative
          name="confirm password"
          prepend-icon="fa fa-lock-alt"
          placeholder="confirm password"
          :type="showPassword ? 'text' : 'password'"
          @input="checkConfirmPassword"
        />
      </base-input-password-container>
    </form>

    <div class="text-muted font-italic">
      <small>
        <!-- eslint-disable vue/no-v-html -->
        <span v-html="updateConfirmPasswordStatus" />
        <!--eslint-enable-->
      </small>
    </div>
  </div>
</template>

<script>
import BaseInputPasswordContainer from '@components/Inputs/BaseInputPasswordContainer.vue'

export default {
  name: 'ValidatedPassword',
  components: { BaseInputPasswordContainer },
  attrs: {
    validPassword: Boolean,
  },

  data () {
    return {
      vPassword: null,
      confirmPassword: null,
      containsValidNumberOfCharacters: false,
      containsNumber: false,
      containsAlpha: false,
      containsOnlyValidCharacters: false,
      validPassword: false,
    }
  },

  computed: {
    listeners () {
      return {
        ...this.$listeners,
        input: this.updateValue,
      }
    },

    updateConfirmPasswordStatus () {
      if (this.confirmPassword == null) {
        return '<span class="text-warning font-weight-700"></span>'
      } else if (this.vPassword === this.confirmPassword) {
        return '<span class="text-success font-weight-700">passwords match!</span>'
      } else {
        return '<span class="text-danger font-weight-700">passwords do not match</span>'
      }
    },
  },

  methods: {
    updateValue (evt) {
      let value = evt.target.value
      this.$emit('input', value)
    },

    checkPassword () {
      let length = this.vPassword.length
      const format = /^(?=.*\d)(?=.*[a-zA-Z])[\w@#$%^&*+=!.?-]{8,26}$/
      this.containsValidNumberOfCharacters = length > 7 && length < 27
      this.containsNumber = /\d/.test(this.vPassword)
      this.containsAlpha = /[a-zA-Z]/.test(this.vPassword)
      this.containsOnlyValidCharacters = format.test(this.vPassword)

      this.validPassword = this.containsOnlyValidCharacters === true &&
        this.containsAlpha === true &&
        this.containsNumber === true
    },

    checkConfirmPassword () {
      if (this.validPassword && this.confirmPassword === this.vPassword) {
        this.sync(this.vPassword, this.validPassword)
      } else {
        this.sync('', false)
      }

      this.updateConfirmPasswordStatus
    },

    sync: function (value, isValid) {
      this.$emit('input', value)
      this.$emit('validated', isValid)
    },
  },
}
</script>

<style scoped>
ul {
  padding-left: 30px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

li {
  margin-bottom: 8px;
  position: relative;
}

li:before {
  content: "";
  width: 0;
  height: 2px;
  background: #2ecc71;
  position: absolute;
  left: 0;
  top: 50%;
  display: block;
  transition: all .6s cubic-bezier(0.175, 0.885, 0.32, 1.275);
}

/* Password Input */

.input_container {
  border: 0;
  position: relative;
}

/* Checkmark & Strikethrough */

.isValid {
  color: rgba(136, 152, 170, 0.8);
}

.isValid:before {
  width: 100%;
}

.checkmark-container {
  position: absolute;
  top: -8px;
  right: -15px;
  width: 50px;
  height: 50px;
  visibility: hidden;
  opacity: 0;
  display: flex;
  align-items: center;
  transition: opacity .4s ease;
}

.showCheckmark {
  visibility: visible;
  opacity: 1;
}

.checkmark {
  width: 100%;
  height: 100%;
  fill: none;
  stroke: #2ecc71;
  stroke-width: 15;
  stroke-linecap: round;
  stroke-dasharray: 180;
  stroke-dashoffset: 180;
}

.checked {
  animation: draw 0.5s ease forwards;
}

.reveal-password-icon {
  position: absolute;
  right: 0.5em;
  top: 50%;
  transform: translateY(-50%);
  cursor: pointer;
  z-index: 99;
  color: #adb5bd;
  width: 30px;
  display: flex;
  justify-content: center;
}

.reveal-password-icon:hover {
  color: #8898aa;
}

@keyframes draw {
  to {
    stroke-dashoffset: 0;
  }
}
</style>
